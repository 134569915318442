import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { useMapStore } from "../../../Stores/MapStore";

import PageTitle from "../../../Components/PageTitle/PageTitle";
import { observer } from "mobx-react";
import { useEffect } from "react";
import Tabs from "../../../Components/Theme/Tabs/Tabs";
import PeriodicFullnessChart from "./PeriodicFullnessChart";
import CumulativeMaxChart from "./CumulativeMaxChart";
import HistoricMapFilter from "../../../Components/Map/HistoricMapFilter/HistoricMapFilter";
import Topbar from "../../../Components/Topbar/Topbar";
import TextualInformation from "./TextualInformation";
import TextualInformationTabFilter from "../../../Components/Map/TextualInformationTabFilter/TextualInformationTabFilter";

const Reports = () => {
  const { t } = useTranslation();
  const {
    initializeData,
    resetFilters,
    historicMapStartDate,
    historicMapEndDate,
    getTrashBinsEventsFromDateToDate,
    filteredHistoricTrashBinsFromDateToDateList,
    selectedTrashBinFromMap,
  } = useMapStore();

  useEffect(() => {
    resetFilters();
    initializeData();
    getTrashBinsEventsFromDateToDate(historicMapStartDate, historicMapEndDate);
  }, [filteredHistoricTrashBinsFromDateToDateList?.splice]);

  const tabs = [
    {
      label: t("reports.AllDates"),
      Component: <PeriodicFullnessChart />,
      historicFilter: (
        <HistoricMapFilter hasStartDate={true} hasEndDate={true} />
      ),
    },
    {
      label: t("reports.CumulativeMax"),
      Component: <CumulativeMaxChart />,
      historicFilter: (
        <HistoricMapFilter hasStartDate={true} hasEndDate={true} />
      ),
    },
    {
      label: t("informations.informations"),
      Component: <TextualInformation />,
      historicFilter: (
        <TextualInformationTabFilter hasStartDate={true} hasEndDate={true} />
      ),
    },
  ];

  return (
    <>
      <Topbar title={t("reports.reports")} />
      <div className="mt-6">
        {/* <PageTitle>
          
        </PageTitle> */}

        <Tabs tabs={tabs} />
      </div>
    </>
  );
};

export default observer(Reports);
