import moment from "moment";

export const formatDateInDateMonthYear = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  }
};

export const formatDateInDateMonthYearHour = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD - HH:mm");
  }
};

export const formatDateInDateMonthYearHourWithoutSpace = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }
};
