import AuthCardWrapper from "../../Components/Theme/AuthCardWrapper";
import {
  Box,
  Divider,
  TextField,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../Components/Theme/Logo";
import { useTheme } from "@mui/material/styles";
import AnimateButton from "../../Components/Theme/Extended/AnimateButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { authStore } from "../../Stores/AuthStore";
import SubmitButton from "../../Components/Theme/Extended/SubmitButton";
import { useState } from "react";
import Images from "../../assets/images";
import { themeColors } from "../../Config/Themes/theme";

export default function ForgotPasswordPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const colors = themeColors(theme.palette.mode);

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const { handleSubmit, control } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = ({ email }) => {
    if (loading) return;

    setLoading(true);

    authStore.forgotPassword(email).finally(() => {
      setLoading(false);
    });
  };

  return (
    <form
      style={{
        backgroundImage: `url(${Images.UnAuthBg})`,
        backgroundSize: "cover",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <AuthCardWrapper pageName="forgotPass">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} paddingBottom="1rem">
            <div className="text-center flex items-center justify-center">
              <Typography
                style={{
                  color: colors.greyAccent.medium,
                }}
                variant="caption"
                fontSize="16px"
              >
                {t("forgotPassword.ForgotPasswordInfo")}
              </Typography>
            </div>
          </Grid>

          <Grid item container paddingBottom="1rem" xs={12}>
            <Typography
              style={{
                color: colors.greenAccent[900],
              }}
              className="mb-3"
              variant="h4"
            >
              {t("forgotPassword.EmailAddress")}
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t("forgotPassword.EmailAddress")}
                    variant="filled"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? t(error.message) : null}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Box sx={{ alignItems: "center", flexDirection: "row" }}>
            <AnimateButton>
              <SubmitButton
                className="capitalize text-base"
                buttonTitle="forgotButton"
                style={{
                  background: colors.greenAccent.main,
                }}
                loading={loading}
                disableElevation
                size="large"
                type="submit"
              />
            </AnimateButton>
          </Box>

          <Grid padding="2px" item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Typography
              component={Link}
              to="/"
              variant="h5"
              sx={{ textDecoration: "none" }}
            >
              {t("forgotPassword.AlreadyHaveAccount")}
            </Typography>
          </Grid>
        </Grid>
      </AuthCardWrapper>
    </form>
  );
}
