import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  FormControl,
} from "@mui/material";

import TextField from "@mui/material/TextField";

// project imports
import AuthCardWrapper from "../../Components/Theme/AuthCardWrapper";
import Logo from "../../Components/Theme/Logo";
import AnimateButton from "../../Components/Theme/Extended/AnimateButton";
import { useState } from "react";
import { useAuthStore } from "../../Stores/AuthStore";
import SubmitButton from "../../Components/Theme/Extended/SubmitButton";
import PasswordInput from "../../Components/Theme/Inputs/PasswordInput";
import { themeColors } from "../../Config/Themes/theme";
import Images from "../../assets/images";
import { success } from "../../Common/Toast/Toast";

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);

  const authStore = useAuthStore();

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(8).max(32).required(),
  });

  const { handleSubmit, control } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    if (loading) return;

    setLoading(true);

    authStore.login(data, navigate, location).finally(() => {
      setLoading(false);
    });
  };

  return (
    <form
      style={{
        backgroundImage: `url(${Images.UnAuthBg})`,
        backgroundSize: "cover",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <AuthCardWrapper className="min-w-full" pageName="login">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid>
            <Link to="#"></Link>
          </Grid>

          <Grid item container direction="column" alignItems="center" xs={12}>
            <Grid item container marginBottom="1rem" xs={12}>
              <FormControl
                sx={{ marginBottom: "1.2rem", width: "100%", top: "1rem" }}
              >
                <Typography
                  style={{
                    color: colors.greenAccent[100],
                  }}
                  className="mb-2"
                  variant="h4"
                >
                  {t("login.EmailAddress")}
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label={t("login.EmailAddress")}
                      variant="filled"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? t(error.message) : null}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              container
              direction="column"
              marginTop="1rem"
              alignItems="center"
              xs={12}
              sx={{ marginTop: "4px" }}
            >
              <FormControl
                sx={{ marginBottom: "1.2rem", width: "100%", bottom: "1rem" }}
              >
                <Typography
                  style={{
                    color: colors.greenAccent[100],
                  }}
                  className="mb-2 mt-3"
                  variant="h4"
                >
                  {t("login.Password")}
                </Typography>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <PasswordInput
                      label={t("login.Password")}
                      onChange={onChange}
                      value={value}
                      error={error}
                      variant="filled"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item container xs={12} justifyContent="end">
              <Typography
                style={{
                  color: colors.greenAccent[200],
                  marginBottom: "10px",
                }}
                component={Link}
                to="/forgot-password"
                variant="subtitle1"
                sx={{ textDecoration: "none" }}
              >
                {t("login.ForgotPassword")}
              </Typography>
            </Grid>

            <Grid padding="2px" item xs={12}>
              <Divider />
            </Grid>

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <SubmitButton
                  className="capitalize text-lg"
                  buttonTitle="loginButton"
                  sx={{ backgroundColor: colors.greenAccent.main }}
                  disableElevation
                  loading={loading}
                  type="submit"
                />
              </AnimateButton>
            </Box>
          </Grid>
        </Grid>
      </AuthCardWrapper>
    </form>
  );
};

export default Login;
