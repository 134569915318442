import React from "react";
// GeneralWaste Trash Bin Icons with fill
import trashBinGeneralWasteInactivePin from "../../../../assets/icons/markerPins/trashBins/generalWaste/trash-bin-generalWaste-pin-inactive.svg";
import trashBinGeneralWastePrimaryPin from "../../../../assets/icons/markerPins/trashBins/generalWaste/trash-bin-generalWaste-pin-primary.svg";
import trashBinGeneralWasteYellowPin from "../../../../assets/icons/markerPins/trashBins/generalWaste/trash-bin-generalWaste-pin-yellow.svg";
import trashBinGeneralWasteOrangePin from "../../../../assets/icons/markerPins/trashBins/generalWaste/trash-bin-generalWaste-pin-orange.svg";
import trashBinGeneralWasteRedPin from "../../../../assets/icons/markerPins/trashBins/generalWaste/trash-bin-generalWaste-pin-red.svg";

// OrganicWaste Trash Bin Icons with fill
import trashBinOrganicWasteInactivePin from "../../../../assets/icons/markerPins/trashBins/organicWaste/trash-bin-organicWaste-pin-inactive.svg";
import trashBinOrganicWastePrimaryPin from "../../../../assets/icons/markerPins/trashBins/organicWaste/trash-bin-organicWaste-pin-primary.svg";
import trashBinOrganicWasteYellowPin from "../../../../assets/icons/markerPins/trashBins/organicWaste/trash-bin-organicWaste-pin-yellow.svg";
import trashBinOrganicWasteOrangePin from "../../../../assets/icons/markerPins/trashBins/organicWaste/trash-bin-organicWaste-pin-orange.svg";
import trashBinOrganicWasteRedPin from "../../../../assets/icons/markerPins/trashBins/organicWaste/trash-bin-organicWaste-pin-red.svg";

// Recycle Trash Bin Icons with fill
import trashBinRecycleInactivePin from "../../../../assets/icons/markerPins/trashBins/recycle/trash-bin-recycle-pin-inactive.svg";
import trashBinRecyclePrimaryPin from "../../../../assets/icons/markerPins/trashBins/recycle/trash-bin-recycle-pin-primary.svg";
import trashBinRecycleYellowPin from "../../../../assets/icons/markerPins/trashBins/recycle/trash-bin-recycle-pin-yellow.svg";
import trashBinRecycleOrangePin from "../../../../assets/icons/markerPins/trashBins/recycle/trash-bin-recycle-pin-orange.svg";
import trashBinRecycleRedPin from "../../../../assets/icons/markerPins/trashBins/recycle/trash-bin-recycle-pin-red.svg";

// EWaste Trash Bin Icons with fill
import trashBinEWasteInactivePin from "../../../../assets/icons/markerPins/trashBins/eWaste/trash-bin-eWaste-pin-inactive.svg";
import trashBinEWastePrimaryPin from "../../../../assets/icons/markerPins/trashBins/eWaste/trash-bin-eWaste-pin-primary.svg";
import trashBinEWasteYellowPin from "../../../../assets/icons/markerPins/trashBins/eWaste/trash-bin-eWaste-pin-yellow.svg";
import trashBinEWasteOrangePin from "../../../../assets/icons/markerPins/trashBins/eWaste/trash-bin-eWaste-pin-orange.svg";
import trashBinEWasteRedPin from "../../../../assets/icons/markerPins/trashBins/eWaste/trash-bin-eWaste-pin-red.svg";

// Glass Trash Bin Icons with fill
import trashBinGlassInactivePin from "../../../../assets/icons/markerPins/trashBins/glass/trash-bin-glass-pin-inactive.svg";
import trashBinGlassPrimaryPin from "../../../../assets/icons/markerPins/trashBins/glass/trash-bin-glass-pin-primary.svg";
import trashBinGlassYellowPin from "../../../../assets/icons/markerPins/trashBins/glass/trash-bin-glass-pin-yellow.svg";
import trashBinGlassOrangePin from "../../../../assets/icons/markerPins/trashBins/glass/trash-bin-glass-pin-orange.svg";
import trashBinGlassRedPin from "../../../../assets/icons/markerPins/trashBins/glass/trash-bin-glass-pin-red.svg";

// Metal Trash Bin Icons with fill
import trashBinMetalInactivePin from "../../../../assets/icons/markerPins/trashBins/metal/trash-bin-metal-pin-inactive.svg";
import trashBinMetalPrimaryPin from "../../../../assets/icons/markerPins/trashBins/metal/trash-bin-metal-pin-primary.svg";
import trashBinMetalYellowPin from "../../../../assets/icons/markerPins/trashBins/metal/trash-bin-metal-pin-yellow.svg";
import trashBinMetalOrangePin from "../../../../assets/icons/markerPins/trashBins/metal/trash-bin-metal-pin-orange.svg";
import trashBinMetalRedPin from "../../../../assets/icons/markerPins/trashBins/metal/trash-bin-metal-pin-red.svg";

// Paper Trash Bin Icons with fill
import trashBinPaperInactivePin from "../../../../assets/icons/markerPins/trashBins/paper/trash-bin-paper-pin-inactive.svg";
import trashBinPaperPrimaryPin from "../../../../assets/icons/markerPins/trashBins/paper/trash-bin-paper-pin-primary.svg";
import trashBinPaperYellowPin from "../../../../assets/icons/markerPins/trashBins/paper/trash-bin-paper-pin-yellow.svg";
import trashBinPaperOrangePin from "../../../../assets/icons/markerPins/trashBins/paper/trash-bin-paper-pin-orange.svg";
import trashBinPaperRedPin from "../../../../assets/icons/markerPins/trashBins/paper/trash-bin-paper-pin-red.svg";

// Plastic Trash Bin Icons with fill
import trashBinPlasticInactivePin from "../../../../assets/icons/markerPins/trashBins/plastic/trash-bin-plastic-pin-inactive.svg";
import trashBinPlasticPrimaryPin from "../../../../assets/icons/markerPins/trashBins/plastic/trash-bin-plastic-pin-primary.svg";
import trashBinPlasticYellowPin from "../../../../assets/icons/markerPins/trashBins/plastic/trash-bin-plastic-pin-yellow.svg";
import trashBinPlasticOrangePin from "../../../../assets/icons/markerPins/trashBins/plastic/trash-bin-plastic-pin-orange.svg";
import trashBinPlasticRedPin from "../../../../assets/icons/markerPins/trashBins/plastic/trash-bin-plastic-pin-red.svg";
import { calculateTrashBinThresholdRange } from "../../../../Common/Map/TrashBinThresholdRange/TrashBinThresholdRange";
import { thresholdStore } from "../../../../Stores/ThresholdStore";
import {
  highBinFullnessKey,
  lowBinFullnessKey,
  mediumBinFullnessKey,
  superLowBinFullnessKey,
} from "../../../../Config/constant";

const getTrashbinMarkerIcon = (marker, isHistoric = false) => {
  const trashIcons = {
    1: {
      inactive: trashBinGeneralWasteInactivePin,
      [superLowBinFullnessKey]: trashBinGeneralWastePrimaryPin,
      [lowBinFullnessKey]: trashBinGeneralWasteYellowPin,
      [mediumBinFullnessKey]: trashBinGeneralWasteOrangePin,
      [highBinFullnessKey]: trashBinGeneralWasteRedPin,
    },
    2: {
      inactive: trashBinRecycleInactivePin,
      [superLowBinFullnessKey]: trashBinRecyclePrimaryPin,
      [lowBinFullnessKey]: trashBinRecycleYellowPin,
      [mediumBinFullnessKey]: trashBinRecycleOrangePin,
      [highBinFullnessKey]: trashBinRecycleRedPin,
    },
    3: {
      inactive: trashBinPaperInactivePin,
      [superLowBinFullnessKey]: trashBinPaperPrimaryPin,
      [lowBinFullnessKey]: trashBinPaperYellowPin,
      [mediumBinFullnessKey]: trashBinPaperOrangePin,
      [highBinFullnessKey]: trashBinPaperRedPin,
    },
    4: {
      inactive: trashBinGlassInactivePin,
      [superLowBinFullnessKey]: trashBinGlassPrimaryPin,
      [lowBinFullnessKey]: trashBinGlassYellowPin,
      [mediumBinFullnessKey]: trashBinGlassOrangePin,
      [highBinFullnessKey]: trashBinGlassRedPin,
    },
    5: {
      inactive: trashBinPlasticInactivePin,
      [superLowBinFullnessKey]: trashBinPlasticPrimaryPin,
      [lowBinFullnessKey]: trashBinPlasticYellowPin,
      [mediumBinFullnessKey]: trashBinPlasticOrangePin,
      [highBinFullnessKey]: trashBinPlasticRedPin,
    },
    6: {
      inactive: trashBinOrganicWasteInactivePin,
      [superLowBinFullnessKey]: trashBinOrganicWastePrimaryPin,
      [lowBinFullnessKey]: trashBinOrganicWasteYellowPin,
      [mediumBinFullnessKey]: trashBinOrganicWasteOrangePin,
      [highBinFullnessKey]: trashBinOrganicWasteRedPin,
    },
    7: {
      inactive: trashBinMetalInactivePin,
      [superLowBinFullnessKey]: trashBinMetalPrimaryPin,
      [lowBinFullnessKey]: trashBinMetalYellowPin,
      [mediumBinFullnessKey]: trashBinMetalOrangePin,
      [highBinFullnessKey]: trashBinMetalRedPin,
    },
    8: {
      inactive: trashBinEWasteInactivePin,
      [superLowBinFullnessKey]: trashBinEWastePrimaryPin,
      [lowBinFullnessKey]: trashBinEWasteYellowPin,
      [mediumBinFullnessKey]: trashBinEWasteOrangePin,
      [highBinFullnessKey]: trashBinEWasteRedPin,
    },
    9: {
      inactive: trashBinGeneralWasteInactivePin,
      [superLowBinFullnessKey]: trashBinGeneralWastePrimaryPin,
      [lowBinFullnessKey]: trashBinGeneralWasteYellowPin,
      [mediumBinFullnessKey]: trashBinGeneralWasteOrangePin,
      [highBinFullnessKey]: trashBinGeneralWasteRedPin,
    },
  };

  const detectDevicePresent = marker.device && marker.device !== null;

  const thresholdRange =
    marker.binPercentageFullness !== undefined
      ? calculateTrashBinThresholdRange(
          thresholdStore.thresholds,
          marker.binPercentageFullness
        )
      : ["inactive"];

  const thresholdRangeHistoric =
    marker?.telemetryTrashBinEvents?.length > 0
      ? calculateTrashBinThresholdRange(
          thresholdStore.thresholds,
          marker.telemetryTrashBinEvents?.length > 0
            ? marker.telemetryTrashBinEvents[0].binPercentageFullness
            : []
        )
      : ["inactive"];

  const getThresholdRange = () =>
    detectDevicePresent
      ? isHistoric
        ? thresholdRangeHistoric
        : thresholdRange
      : "inactive";

  const icon =
    trashIcons[marker?.trashBinTypeId]?.[getThresholdRange()] || null;

  return icon ? { url: icon } : null;
};

export default getTrashbinMarkerIcon;
