import { observer } from "mobx-react";
// material ui
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";

// project imports
import { useTranslation } from "react-i18next";
import { useMapStore } from "../../../Stores/MapStore";
import { themeColors } from "../../../Config/Themes/theme";
import RoundIcon from "../../../Components/RoundIcon/RoundIcon";
import NoDataCard from "../../../Components/NoDataCard/NoDataCard";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const TrashBinTextualInformationTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mapStore = useMapStore();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  useEffect(() => {
    mapStore.getTextualInformationFromDateToDate(
      mapStore.historicMapStartDate,
      mapStore.historicMapEndDate
    );
  }, [mapStore.textualInformation?.length]);

  useEffect(() => {
    mapStore.resetFilters();
    mapStore.initializeData();
  }, []);

  const viewTrashBin = (trashBinId) => {
    navigate(`/trashBin/${trashBinId}`);
  };

  const columns = [
    {
      field: "id",
      headerName: t("informations.Id"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 143,
    },
    {
      field: "category",
      headerName: t("trashBins.TrashBinTypes"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 350,
      renderCell: (params) => <RoundIcon type={"type"} id={params.value} />,
    },
    {
      field: "address",
      headerName: t("trashBins.Address"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 350,
    },
    {
      field: "pickups",
      headerName: t("informations.pickups"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 272,
    },
    {
      field: "pickedLitres",
      headerName: t("informations.collectedTrashInLitres"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 250,
    },
    {
      field: "actions",
      headerName: t("misc.Actions"),
      headerClassName: "trashBin-table-theme--header remove-icon",
      cellClassName: "trashBin-table-theme--row",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Stack
          className="mr-36"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Tooltip
            placement="top"
            title={t("misc.View")}
            onClick={() => viewTrashBin(params?.row?.id)}
          >
            <IconButton color="#333333B2" size="large">
              <Visibility sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  const getRows = () => {
    return mapStore?.textualInformation?.trashBins?.map((trashbins) => ({
      id: trashbins?.id,
      category: trashbins?.category,
      address: trashbins?.address,
      pickups: trashbins?.pickups,
      pickedLitres: trashbins?.pickupLitres + " L",
    }));
  };

  return (
    <>
      {mapStore.textualInformation?.trashBins?.length ? (
        <>
          <DataGrid
            sx={{
              height: 200,
              width: "100%",
              "& .trashBin-table-theme--header": {
                backgroundColor: `${colors.greyAccent.light3}`,
              },
              "& .trashBin-table-theme--row": {
                backgroundColor: "#FFFFFF",
              },
              " & .MuiDataGrid-footerContainer": {
                backgroundColor: "#FFFFFF",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              sorting: {
                sortModel: [{ field: "id", sort: "asc" }],
              },
            }}
            disableColumnSelector
            columns={columns}
            rows={getRows()}
            autoHeight
            disableSelectionOnClick
          />
        </>
      ) : (
        <NoDataCard />
      )}
    </>
  );
};

export default observer(TrashBinTextualInformationTable);
