import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, themeColors } from "../../Config/Themes/theme";
import { InputBase } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { observer } from "mobx-react";
import ProfileToolTip from "./ProfileToolTip";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

const Topbar = ({ title = "" }) => {
  const theme = useTheme();
  const colors = themeColors();

  return (
    <Box
      className="p-0 pb-2 w-full border-b-2 border-gray-300"
      display="flex"
      justifyContent="space-between"
      p={2}
    >
      <div className="mt-2">
        {title ? (
          <Typography
            className="font-bold text-[32px]"
            color={colors.greyAccent.dark2}
            gutterBottom
          >
            {title}
          </Typography>
        ) : null}
      </div>
      <Box display="flex"></Box>

      {/* ICONS */}
      <Box display="flex">
        <LanguageSwitcher />

        <IconButton>
          <ProfileToolTip />
        </IconButton>
      </Box>
    </Box>
  );
};

export default observer(Topbar);
