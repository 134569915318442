import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function PasswordInput({
  label,
  value,
  onChange,
  error,
  variant,
  fullWidth,
  className,
}) {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    setPasswordVisible((oldPassword) => !oldPassword);
  }, [setPasswordVisible]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      label={label}
      fullWidth={fullWidth}
      className={className}
      variant={variant || "outlined"}
      type={passwordVisible ? "text" : "password"}
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={error ? t(error?.message, { min: 8, max: 32 }) : null}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleVisibility}
              onMouseDown={handleMouseDownPassword}
            >
              {passwordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.object,
};

export default PasswordInput;
