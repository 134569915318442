import PropTypes from "prop-types";

// material-ui
import { Box, Grid, Typography } from "@mui/material";

// project import
import MainCard from "./Cards/MainCard";
import AuthFooter from "./Cards/AuthFooter";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../Config/Themes/theme";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const RenderPageTitle = (pageName) => {
const { t } = useTranslation();
  if (pageName === "login") {
    return (
      <div className="text-center">
        <Typography
          style={{
            color: "#FFFFFF",
          }}
          className="mb-2"
          variant="h4"
        >
          {t("login.LoginToSmartBins")}
        </Typography>
      </div>
    );
  } else if (pageName === "forgotPass") {
    return (
      <div className="text-center">
        <Typography
          style={{
            color: "#FFFFFF",
          }}
          className="mb-2"
          variant="h4"
        >
          {t("forgotPassword.ResetPassword")}
        </Typography>
      </div>
    );
  }
};
const AuthCardWrapper = ({ pageName = "", children, ...other }) => (
  <Grid
    container
    direction="column"
    justifyContent="flex-end"
    sx={{ minHeight: "100vh" }}
  >
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "calc(100vh - 68px)" }}
      >
        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
          {RenderPageTitle(pageName)}
          <MainCard
            sx={{
              maxWidth: { xs: 400, lg: 475 },
              margin: { xs: 2.5, md: 3 },
              "& > *": {
                flexGrow: 1,
                flexBasis: "50%",
              },
            }}
            content={false}
            {...other}
          >
            <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
          </MainCard>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
      <AuthFooter />
    </Grid>
  </Grid>
);

AuthCardWrapper.propTypes = {
  children: PropTypes.node,
};

export default AuthCardWrapper;
