import { observer } from "mobx-react";
import TrashBinTextualInformationTable from "./TrashBinTextualInformationTable";
import CategoryTextualInformationTable from "./CategoryTextualInformationTable";
import MainCard from "../../../Components/Theme/Cards/MainCard";
import { useMapStore } from "../../../Stores/MapStore";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const TextualInformation = () => {
  const { t } = useTranslation();
  const mapStore = useMapStore();

  useEffect(() => {
    mapStore.getTextualInformationFromDateToDate(
      mapStore.historicMapStartDate,
      mapStore.historicMapEndDate
    );
  }, [mapStore.textualInformation?.length]);

  return (
    <MainCard className="bg-[#F9F9F9]">
      <Grid
        className="mt-4"
        container
        spacing={2}
        alignItems="start"
        justifyContent="start"
      >
        <span className="text-start text-2xl">
          <div>
            <div>{t("informations.generalInformation")}</div>
          </div>
        </span>
      </Grid>
      <Grid
        className="mt-2"
        container
        alignItems="start"
        justifyContent="start"
      >
        <ul className="bg-[#F9F9F9] p-4 rounded-lg">
          <li className="mb-4">
            ● <strong>{t("informations.totalNumberOfTrashBins")}:</strong>{" "}
            {mapStore?.textualInformation?.numberOfTrashBins}
          </li>
          <li className="mb-4">
            ● <strong>{t("informations.pickups")}:</strong>{" "}
            {mapStore?.textualInformation?.pickups}
          </li>
          <li className="mb-4">
            ● <strong>{t("informations.collectedTrashInLitres")}:</strong>{" "}
            {mapStore?.textualInformation?.pickedLitres}
          </li>
        </ul>
      </Grid>
      <Grid
        className="mt-6"
        container
        spacing={2}
        alignItems="start"
        justifyContent="start"
      >
        <span className="text-start text-2xl">
          <div>
            <div>{t("informations.forEachCategory")}</div>
          </div>
        </span>
      </Grid>
      <div className="mt-8 items-center justify-center flex">
        <CategoryTextualInformationTable />
      </div>
      <Grid
        className="mt-8"
        container
        spacing={2}
        alignItems="start"
        justifyContent="start"
      >
        <span className="text-start text-2xl">
          <div>
            <div>{t("informations.forEachTrashBin")}</div>
          </div>
        </span>
      </Grid>
      <div className="mt-8 items-center justify-center flex">
        <TrashBinTextualInformationTable />
      </div>
    </MainCard>
  );
};

export default observer(TextualInformation);
