import { observer } from "mobx-react";
// material ui
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";

// project imports
import { useTranslation } from "react-i18next";
import { useMapStore } from "../../../Stores/MapStore";
import { themeColors } from "../../../Config/Themes/theme";
import RoundIcon from "../../../Components/RoundIcon/RoundIcon";
import NoDataCard from "../../../Components/NoDataCard/NoDataCard";
import { DataGrid } from "@mui/x-data-grid";

const CategoryTextualInformationTable = () => {
  const { t } = useTranslation();
  const mapStore = useMapStore();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  useEffect(() => {
    mapStore.getTextualInformationFromDateToDate(
      mapStore.historicMapStartDate,
      mapStore.historicMapEndDate
    );
  }, [mapStore.textualInformation?.length]);

  useEffect(() => {
    mapStore.resetFilters();
    mapStore.initializeData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: t("informations.Id"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 393,
    },
    {
      field: "category",
      headerName: t("trashBins.TrashBinTypes"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 450,
      renderCell: (params) => <RoundIcon type={"type"} id={params.value} />,
    },
    {
      field: "pickups",
      headerName: t("informations.pickups"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 372,
    },
    {
      field: "pickedLitres",
      headerName: t("informations.collectedTrashInLitres"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 350,
    },
  ];

  const getRows = () => {
    return mapStore?.textualInformation?.categories?.map((categories) => ({
      id: categories?.id,
      category: categories?.id,
      pickups: categories?.pickups,
      pickedLitres: categories?.pickedLitres + " L",
    }));
  };

  return (
    <>
      {mapStore.textualInformation?.categories?.length ? (
        <>
          <DataGrid
            sx={{
              height: 200,
              width: "100%",
              "& .trashBin-table-theme--header": {
                backgroundColor: `${colors.greyAccent.light3}`,
              },
              "& .trashBin-table-theme--row": {
                backgroundColor: "#FFFFFF",
              },
              " & .MuiDataGrid-footerContainer": {
                backgroundColor: "#FFFFFF",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              sorting: {
                sortModel: [{ field: "id", sort: "asc" }],
              },
            }}
            disableColumnSelector
            columns={columns}
            rows={getRows()}
            autoHeight
            disableSelectionOnClick
          />
        </>
      ) : (
        <NoDataCard />
      )}
    </>
  );
};

export default observer(CategoryTextualInformationTable);
