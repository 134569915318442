import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import { observer } from "mobx-react";
import { themeColors } from "../../Config/Themes/theme";
import { useMapStore } from "../../Stores/MapStore";
import RoundIcon from "../../Components/RoundIcon/RoundIcon";
import NoDataCard from "../../Components/NoDataCard/NoDataCard";
import { useState } from "react";
import ExportToExcel from "../../Components/ExportToExcel/ExportToExcel";
import { DataGrid } from "@mui/x-data-grid";

const TableReport = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const {
    initializeData,
    resetFilters,
    filteredHistoricTrashBinsForDateList,
    historicMapStartDate,
    getTrashBinsEventsForDate,
  } = useMapStore();

  const exportFullnessAsEmptyCell = (row) => {
    return row.telemetryTrashBinEvents?.length > 0
      ? row.telemetryTrashBinEvents[0].binPercentageFullness
      : null;
  };

  const showHistoricBinPercentageFullness = (row) => {
    // TODO remove division when endpoint sends correct binPercentageFullness
    return row.telemetryTrashBinEvents?.length > 0
      ? row.telemetryTrashBinEvents[0].binPercentageFullness
      : 0;
  };

  const [tableData, setTableData] = useState([]);

  const initializeDataToExport = () => {
    const dataToExportArr = [];
    // console.log("List count: ", filteredHistoricTrashBinsForDateList);
    filteredHistoricTrashBinsForDateList?.map((trashBinsHistory) => {
      const newTrashBinsHistory = {
        [t("trashBins.Id")]: trashBinsHistory.id,
        [t("trashBins.TrashBinTypes")]: trashBinsHistory?.trashBinTypeId,
        [t("trashBins.Address")]: trashBinsHistory?.address,
        [t("trashBins.Name")]: trashBinsHistory?.name,
        [t("trashBins.Description")]: trashBinsHistory?.description,
        [t("trashBinDevices.TrashBinDevices")]:
          trashBinsHistory?.device?.nativeId,
        [t("trashBins.TrashBinFullness")]:
          exportFullnessAsEmptyCell(trashBinsHistory),
      };
      dataToExportArr.push(newTrashBinsHistory);
      setTableData(dataToExportArr);
      return newTrashBinsHistory;
    });
  };

  useEffect(() => {
    initializeDataToExport();
  }, [filteredHistoricTrashBinsForDateList?.length]);

  useEffect(() => {
    resetFilters();
    initializeData();
    getTrashBinsEventsForDate(historicMapStartDate);
    // console.log(
    //   "FullnessHistory ==> filteredHistoricTrashBinsForDateList",
    //   JSON.stringify(filteredHistoricTrashBinsForDateList)
    // );
  }, [filteredHistoricTrashBinsForDateList?.splice]);

  const CustomLinearProgress = styled(LinearProgress)(({ value }) => ({
    height: "5px",
    width: "70%",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor:
        value >= 0 && value <= 25
          ? "#168D8B" // green for values between 0 and 25
          : value > 25 && value <= 50
          ? "#EFDB22" // yellow for values between 25 and 50
          : value > 50 && value <= 75
          ? "#FF7D00" // orange for values between 50 and 75
          : value > 75 && value <= 100
          ? "#D00404" // red for values between 75 and 100
          : "#D1D5DB", // undefined if value is not within 0-100
    },
  }));

  const columns = [
    {
      field: "id",
      headerName: t("trashBins.Id"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 70,
      align: "left",
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] ml-1"
        >
          {params?.value ? params?.value : "-"}
        </Typography>
      ),
    },
    {
      field: "trashBinTypeId",
      headerName: t("trashBins.TrashBinTypes"),
      cellClassName: "trashBin-table-theme--row",
      headerClassName: "trashBin-table-theme--header",
      width: 150,
      align: "left",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] ml-4"
        >
          {params.value ? <RoundIcon type={"type"} id={params.value} /> : "-"}
        </Typography>
      ),
    },
    {
      field: "address",
      headerName: t("trashBins.Address"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 350,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.value ? params?.value : "-"}
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: t("trashBins.Name"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 200,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.value ? params?.value : "-"}
        </Typography>
      ),
    },
    {
      field: "description",
      headerName: t("trashBins.Description"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 291,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.value ? params?.value : "-"}
        </Typography>
      ),
    },
    {
      field: "nativeId",
      headerName: t("trashBinDevices.TrashBinDevices"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 300,
      align: "left",
      valueGetter: (params) =>
        params.row.device?.nativeId ? params.row.device?.nativeId : "-",
    },
    {
      field: "binPercentageFullness",
      headerName: t("trashBins.TrashBinFullness"),
      headerClassName: "trashBin-table-theme--header",
      cellClassName: "trashBin-table-theme--row",
      width: 250,
      sortable: false,
      renderCell: (params) => (
        <div style={{ width: "100%" }}>
          <Typography
            align="left"
            variant="subtitle1"
            component="div"
            className="flex items-center text-[14px] ml-2"
          >
            {showHistoricBinPercentageFullness(params.row)} %
          </Typography>
          <CustomLinearProgress
            variant="determinate"
            value={showHistoricBinPercentageFullness(params.row)}
            valueBuffer={showHistoricBinPercentageFullness(params.row)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center justify-end mb-4">
        <ExportToExcel
          tableData={tableData}
          fileName={t("filenames.FullnessHistory")}
        />
      </div>
      {filteredHistoricTrashBinsForDateList?.length ? (
        <div className="mt-6">
          <DataGrid
            sx={{
              height: 300,
              width: "100%",
              "& .trashBin-table-theme--header": {
                backgroundColor: `${colors.greyAccent.light3}`,
              },
              "& .trashBin-table-theme--row": {
                backgroundColor: "#FFFFFF",
              },
              " & .MuiDataGrid-footerContainer": {
                backgroundColor: "#FFFFFF",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              sorting: {
                sortModel: [{ field: "id", sort: "asc" }],
              },
            }}
            disableColumnSelector
            columns={columns}
            rows={filteredHistoricTrashBinsForDateList || []}
            loading={!filteredHistoricTrashBinsForDateList}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      ) : (
        <NoDataCard />
      )}
    </>
  );
};

export default observer(TableReport);
