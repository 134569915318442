import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";

class ThresholdStore {
  thresholds = {};

  constructor() {
    makeObservable(this, {
      thresholds: observable,
      getThresholds: action,
      updateThreshold: action,
    });
  }

  getThresholds = () => {
    return AuthSetAgent.thresholds
      .getThresholds()
      .then((thresholdsResp) => {
        return (this.thresholds = thresholdsResp
          ? thresholdsResp.threshold
          : {});
      })
      .catch((error) => {});
  };

  updateThreshold(data) {
    return AuthSetAgent.thresholds.updateThreshold(data);
  }
}

export const thresholdStore = new ThresholdStore();
export const ThresholdContext = createContext(thresholdStore);
export const ThresholdProvider = ({ children, store }) => (
  <ThresholdContext.Provider value={store}>
    {children}
  </ThresholdContext.Provider>
);
export const useThresholdStore = () => useContext(ThresholdContext);
