import { useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { googleApiKey } from '../../../Config/constant';

export default function useMapLoader() {
    const libraries = useMemo(() => ['places'], []);

    const loader = useJsApiLoader({
        id: 'script-loader',
        googleMapsApiKey: googleApiKey,
        libraries,
        language: 'el',
        region: 'gr',
    });

    return useMemo(() => loader, [loader]);
}