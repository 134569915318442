// theme constant
export const gridSpacing = 3;
export const drawerWidth = 0;
export const appDrawerWidth = 320;
export const defaultLanguage = "el";

export const encryptionKey = "niebwfi&2e2dDW(#435";
export const baseUrl = process.env.REACT_APP_BASE_URL;

export const tokenKey = "token";
export const refreshTokenKey = "refresh_token";
export const languageKey = "language";

// export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const googleApiKey = "AIzaSyBwLcCElELjZm_ChjIpABi8lcl07OCWZcg";

// ThrashBin Thresholds Keys
export const superLowBinFullnessKey = "threshold-superLowBinFullness";
export const lowBinFullnessKey = "threshold-lowBinFullness";
export const mediumBinFullnessKey = "threshold-mediumBinFullness";
export const highBinFullnessKey = "threshold-highBinFullness";
