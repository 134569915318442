import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircularSpinner from "../Spinners/CircularSpinner";

function SubmitButton({
  bgColor = "#168D8B", //Not used yet //TODO: to be implemented
  title, //Not used yet //TODO: to be implemented
  titleColor = "#FFFFFF", //Not used yet//TODO: to be implemented
  heading = "h4", //Not used yet //TODO: to be implemented
  elmType = "",
  buttonTitle = "",
  loading,
  size,
  type,
  variant,
  color,
  onClick,
  ...others
}) {
  const { t } = useTranslation();

  const RenderButtonTitle = () => {
    const { t } = useTranslation();
    if (buttonTitle === "loginButton") {
      return (
        <Typography
          style={{
            color: "#FFFFFF",
          }}
        >
          {t("login.Login")}
        </Typography>
      );
    } else if (buttonTitle === "forgotButton") {
      return (
        <div
          style={{
            color: "#FFFFFF",
          }}
          variant="h4"
        >
          {t("forgotPassword.SendLink")}
        </div>
      );
    } else if (buttonTitle === "addBinButton") {
      return (
        <div
          style={{
            color: "#FFFFFF",
          }}
          variant="h4"
        >
          {t("forms.Submit")}
        </div>
      );
    } else if (buttonTitle === "addUserButton") {
      return (
        <div
          style={{
            color: "#FFFFFF",
          }}
          variant="h4"
        >
          {t("forms.Submit")}
        </div>
      );
    } else if (buttonTitle === "updateThresholdButton") {
      return (
        <div
          style={{
            color: "#FFFFFF",
          }}
          variant="h4"
        >
          {t("forms.Submit")}
        </div>
      );
    } else if (buttonTitle === "submit") {
      return (
        <Typography
          style={{
            color: "#FFFFFF",
          }}
        >
          {t("forms.Submit")}
        </Typography>
      );
    } else if (buttonTitle === "export") {
      return (
        <Typography
          style={{
            color: "#FFFFFF",
          }}
        >
          {t("forms.exportData")}
        </Typography>
      );
    } else if (buttonTitle === "import") {
      return (
        <Typography
          style={{
            color: "#FFFFFF",
          }}
        >
          {t("trashBins.MassImport")}
        </Typography>
      );
    } else if (buttonTitle === "binDeviceImport") {
      return (
        <Typography
          style={{
            color: "#FFFFFF",
          }}
        >
          {t("trashBinDevices.MassImport")}
        </Typography>
      );
    } else if (buttonTitle === "exportToPdf") {
      return (
        <Typography
          style={{
            color: "#FFFFFF",
          }}
        >
          {t("exportChart.exportToPdf")}
        </Typography>
      );
    } else if (buttonTitle === "addComponent") {
      return (
        <Typography
          style={{
            color: "#FFFFFF",
          }}
        >
          {t("addComponent.addComponent")}
        </Typography>
      );
    }
    // else if (buttonTitle === "addComponent") {
    //       return (
    //         <Typography
    //           style={{
    //             color: "#FFFFFF",
    //           }}
    //         >
    //           {title}
    //         </Typography>
    //       );
    //     }
    //  }
    else {
      return (
        <Typography
          style={{
            color: "#FFFFFF",
          }}
        >
          {title}
        </Typography>
      );
    }
  };

  //TODO: to be implemented. It is already ready to use
  const RenderButtonElm = () => {
    return (
      <div
        style={{
          color: bgColor,
        }}
        variant={heading}
      >
        <Typography
          style={{
            color: titleColor,
          }}
        >
          {title}
        </Typography>
      </div>
    );
  };

  return (
    <Button
      size={size || "large"}
      type={type || "button"}
      onClick={onClick}
      variant={variant || "contained"}
      color={color || "primary"}
      disabled={loading}
      {...others}
    >
      {loading && (
        <CircularSpinner
          size="20px"
          color="inherit"
          sx={{ marginRight: "15px" }}
        />
      )}
      {RenderButtonTitle()}
      {/* {RenderButtonElm()} */}
    </Button>
  );
}

export default SubmitButton;
