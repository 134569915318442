import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { themeColors } from "../../Config/Themes/theme";
import { useTranslation } from "react-i18next";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Icons from "../../assets/icons";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import Logo from "../../Components/Theme/Logo";
import { useAuthStore } from "../../Stores/AuthStore";
import { observer } from "mobx-react";
import { alertStore } from "../../Stores/AlertStore";

const Item = ({ title, to, icon, selected, setSelected, badge }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.whiteAccent.main,
        background: selected === title ? colors.bg.whiteTransp["01"] : null,
        marginRight: selected === title ? "20px" : null,
        borderRadius: selected === title ? "4px" : null,
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      {/* <Typography className={selected === title ? "font-bold" : null}> */}
      <div className="flex items-center justify-between">
        <Typography>{title}</Typography>
        <Typography className="flex items-center justify-center">
          {badge}
        </Typography>
      </div>
      <Link to={to} />
    </MenuItem>
  );
};

const SidebarMenu = () => {
  const theme = useTheme();
  const authStore = useAuthStore();
  const { t } = useTranslation();
  const colors = themeColors(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const badgeElm = () => {
    return (
      <span
        className="blink inline-block leading-none text-center whitespace-nowrap align-baseline font-bold"
        style={{
          background: colors.orangeAccent.main,
          width: "10px",
          height: "10px",
          borderRadius: 50,
        }}
      >
        &nbsp;
      </span>
    );
  };

  const renderMenuHeader = () => {
    return (
      <MenuItem
        onClick={() => setIsCollapsed(!isCollapsed)}
        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
        style={{
          margin: "10px 0 20px 0",
          color: colors.blackAccent.main,
        }}
      >
        {!isCollapsed && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            ml="15px"
          >
            {/* <Typography variant="h5" color={colors.blackAccent.main}>
              SMART BINS LOGO
            </Typography> */}
            <Logo />
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </MenuItem>
    );
  };

  const renderUserMenuItems = () => {
    return (
      <>
        <Typography
          className="font-bold"
          variant="h6"
          color={colors.whiteAccent.main}
          sx={{
            m: "15px 0 5px 15px",
            fontSize: isCollapsed ? "10px" : "initial",
          }}
        >
          {t("misc.General")}
        </Typography>
        <Item
          title={t("misc.Dashboard")}
          to="/"
          icon={
            <Icons
              name="dashBoard"
              width={23}
              height={23}
              fill={colors.whiteAccent.main}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />

        <Item
          title={t("historical.Historical")}
          to="/fullnessHistory"
          icon={
            <Icons
              name="map"
              width={25}
              height={25}
              fill={colors.whiteAccent.main}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />

        <Item
          title={t("trashBins.TrashBinsManagement")}
          to="/trashBin"
          icon={
            <Icons
              name="trashBin"
              width={28}
              height={28}
              fill={colors.whiteAccent.main}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />

        <Item
          title={t("trashBinDevices.TrashBinDevicesManagement")}
          to="/trashBinDevice"
          icon={
            <Icons
              name="trashBinDevice"
              width={28}
              height={28}
              fill={colors.whiteAccent.main}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />

        <Item
          title={t("reports.reports")}
          to="/reports"
          icon={
            <Icons
              name="threshold"
              width={28}
              height={28}
              fill={colors.whiteAccent.main}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        {!alertStore.hasAlert ? (
          <>
            <Item
              title={t("alerts.Alerts")}
              to="/alert"
              icon={
                <Icons
                  name="alert"
                  width={26}
                  height={26}
                  fill={colors.whiteAccent.main}
                />
              }
              selected={selected}
              setSelected={setSelected}
            />
          </>
        ) : (
          <>
            <Item
              title={t("alerts.Alerts")}
              to="/alert"
              icon={
                <Icons
                  name="alert"
                  width={26}
                  height={26}
                  fill={colors.whiteAccent.main}
                />
              }
              badge={badgeElm()}
              selected={selected}
              setSelected={setSelected}
            />
          </>
        )}
        {authStore.canUserAdjustThreshold ? (
          <>
            <Typography
              className="font-bold"
              variant="h6"
              color={colors.whiteAccent.main}
              sx={{
                m: "15px 0 5px 15px",
                fontSize: isCollapsed ? "8px" : "initial",
              }}
            >
              {t("misc.Administration")}
            </Typography>
            <Item
              title={t("threshold.Threshold")}
              to="/threshold"
              icon={
                <Icons
                  name="gear"
                  width={26}
                  height={26}
                  fill={colors.whiteAccent.main}
                />
              }
              selected={selected}
              setSelected={setSelected}
            />
          </>
        ) : null}
      </>
    );
  };

  const renderAdminMenuItems = () => {
    return (
      <>
        <Typography
          className="font-bold"
          variant="h6"
          color={colors.whiteAccent.main}
          sx={{
            m: "15px 0 5px 15px",
            fontSize: isCollapsed ? "8px" : "initial",
          }}
        >
          {t("misc.Administration")}
        </Typography>

        <Item
          title={t("users.UsersList")}
          to="/users"
          icon={
            <Icons
              name="profile"
              width={28}
              height={28}
              fill={colors.whiteAccent.main}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
      </>
    );
  };

  return (
    <Box
      className="sticky top-0 h-screen"
      sx={{
        "& .pro-sidebar-inner": {
          // background: `${colors.primary[400]} !important`,
          background: `${colors.primary.main}!important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          // color : "#868dfb !important",
          color: "#F2F2F2 !important",
        },
        "& .pro-menu-item.active": {
          // color : "#6870fa !important",
          color: "#FFFFFF !important",
        },
      }}
      style={{ height: screenHeight }}
    >
      {/* {JSON.stringify(authStore.authUser)} */}
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {renderMenuHeader()}

          {/* MENU ITEMS */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {authStore.isUser && renderUserMenuItems()}

            {authStore.isAdmin && renderAdminMenuItems()}
          </Box>
        </Menu>
        {!isCollapsed ? (
          <div class="text-[#FFFF] ml-4 mr-2 mt-[165%]">
            <div>
              Copyright © 2023. trifylia.telestoiot.gr | All Rights Reserved
              <br />
              Designed by: <a>Telesto Technologies</a>
            </div>
          </div>
        ) : null}
      </ProSidebar>
    </Box>
  );
};

export default observer(SidebarMenu);
