import axios from "axios";
import { warning, error } from "../Toast/Toast";
import { baseUrl } from "../../Config/constant";
import { authStore } from "../../Stores/AuthStore";

axios.defaults.baseURL = baseUrl;
// eslint-disable-next-line no-return-assign
export const setBearerToken = (token) =>
  (axios.defaults.headers.common = { Authorization: `Bearer ${token}` });
axios.interceptors.response.use(undefined, (er) => {
  const { response } = er;

  if (response?.status === 400) {
    // eslint-disable-next-line no-nested-ternary
    warning(
      response?.data.length > 0
        ? Array.isArray(response?.data)
          ? response?.data[0]
          : response?.data
        : "Something went wrong!"
    );
  }

  if (response?.status === 401) {
    warning("You have not access for this action!");
    // authStore.logout();
    setTimeout(() => {
      window.location.href = "/noAccessCard";
    }, "1000");
  }

  if (response?.status === 404) {
    warning("Something went wrong! (Error 404)");
  }

  if (response?.status === 500) {
    error("Something went wrong! Please, try again later!");
  }

  if (er.code === "ERR_NETWORK") {
    warning("Network error!");
  }
});

const responseBody = (response) => response?.data;

export const requests = {
  get: (url) => axios.get(url).then(responseBody),
  getFile: (url) => axios.get(url, { responseType: "blob" }),
  post: (url, body, config = {}) =>
    axios.post(url, body, config).then(responseBody),
  patch: (url, body) => axios.patch(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  delete: (url, body) => axios.delete(url, body).then(responseBody),
};
