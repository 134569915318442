import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { themeColors } from "../../../Config/Themes/theme";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useMapStore } from "../../../Stores/MapStore";
import {
  formatDateInDateMonthYear,
  formatDateInDateMonthYearHour,
} from "../../../Common/formatDate/formatDate";
import LineChart from "../../../Components/Charts/LineChart/LineChart";
import Select from "react-select";
import NoDataCard from "../../../Components/NoDataCard/NoDataCard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import SubmitButton from "../../../Components/Theme/Extended/SubmitButton";
import AnimateButton from "../../../Components/Theme/Extended/AnimateButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const CumulativeMaxChart = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const {
    initializeData,
    resetFilters,
    historicMapStartDate,
    historicMapEndDate,
    filteredHistoricTrashBinsFromDateToDateList,
    trashBinsEventsFromDateToDateList,
    //selectedTrashBinId,
    setSelectedTrashBinId,
    getTrashBinsEventsFromDateToDate,
    shouldFetchHistoricMapData,
  } = useMapStore();

  //const [mapSelectedTrashbin, setMapSelectedTrashBin] = useState(null);
  const [isClearable, setIsClearable] = useState(null);
  const [isSearchable, setIsSearchable] = useState(true);
  const [componentList, setComponentList] = useState([]);

  const rgb = colors.primary.main.match(/\w\w/g).map((x) => parseInt(x, 16));

  // const getRandomRGBColor = (alpha) => {
  //   const r = Math.floor(Math.random() * 256);
  //   const g = Math.floor(Math.random() * 256);
  //   const b = Math.floor(Math.random() * 256);
  //   return `rgba(${r},${g},${b},${alpha})`;
  // };

  const addNewChart = () => {
    const newComponent = {
      selectedTrashBinId: [],
      graphData: { labels: [], datasets: [] },
    };
    const newComponentListData = [...componentList, newComponent];
    // console.log("newComponentListData", newComponentListData);
    setComponentList(newComponentListData);
  };

  const removeNewChart = (index) => {
    setComponentList((prevList) => {
      const updatedList = [...prevList];
      updatedList.splice(index, 1);
      // console.log("updatedList", updatedList);
      return updatedList;
    });
  };

  const handleOnChange = (events) => {
    if (events && events.length > 0) {
      setIsClearable(true);
      setSelectedTrashBinId(events);
    } else {
      setIsClearable(false);
      setSelectedTrashBinId(null);
    }
  };

  const handleAlternativeSelect = (events, index) => {
    if (events) {
      const selectedOptions = Array.isArray(events) ? events : [events];

      const selectedTrashBinIds = selectedOptions.map((option) => ({
        value: option.value,
        label: option.label,
      }));

      const graphData = initializeNewChartGraphData(selectedTrashBinIds);

      const updatedComponentList = [...componentList];
      updatedComponentList[index] = {
        ...updatedComponentList[index],
        selectedTrashBinId: selectedTrashBinIds,
        graphData: graphData,
      };
      setComponentList(updatedComponentList);
      setIsClearable(true);
    } else {
      setIsClearable(false);
      setComponentList([]);
    }
  };

  const initializeGraphData = () => {
    const datasets = [];
    const labels = new Set();
    const backgroundColor = colors.primary.main;
    const borderColor = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`;

    const uniqueBins = [
      ...new Set(
        filteredHistoricTrashBinsFromDateToDateList.map((item) => item.id)
      ),
    ];

    uniqueBins.forEach((binId, index) => {
      const binData = filteredHistoricTrashBinsFromDateToDateList.find(
        (historicTrashBin) => historicTrashBin.id === binId
      );

      const data = {};

      if (binData.telemetryTrashBinEvents?.length > 0) {
        binData.telemetryTrashBinEvents.forEach((event) => {
          const date = formatDateInDateMonthYear(event.timestamp);
          const fullness = event.binPercentageFullness;

          if (!data[date] || data[date] < fullness) {
            data[date] = fullness;
          }

          labels.add(date);
        });

        const dataset = {
          label: "Id: " + binData.id + " Name: " + binData.name,
          data: Array.from(labels).map((date) => data[date] || null),
          lineTension: 0.4,
          fill: false,
          id: binData.id,
          address: binData.address,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
        };

        datasets.push(dataset);
      }
    });

    return {
      labels: Array.from(labels),
      datasets: datasets,
    };
  };

  const initializeNewChartGraphData = (selectedTrashBinIds = []) => {
    const datasets = [];
    const labels = new Set();
    const backgroundColor = colors.primary.main;
    const borderColor = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`;

    if (selectedTrashBinIds?.length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const mySelectedTrashBins = trashBinsEventsFromDateToDateList.filter(
      (trashBin) =>
        selectedTrashBinIds.some(
          (selectedTrashBin) => selectedTrashBin.value === trashBin.id
        )
    );

    mySelectedTrashBins.forEach((selectedTrashBin) => {
      const binData = trashBinsEventsFromDateToDateList.find(
        (historicTrashBin) => historicTrashBin.id === selectedTrashBin.id
      );

      const data = {};

      if (binData.telemetryTrashBinEvents?.length > 0) {
        binData.telemetryTrashBinEvents.forEach((event) => {
          const date = formatDateInDateMonthYear(event.timestamp);
          const fullness = event.binPercentageFullness;

          if (!data[date] || data[date] < fullness) {
            data[date] = fullness;
          }

          labels.add(date);
        });

        const dataset = {
          label: "Id: " + binData.id + " Name: " + binData.name,
          data: Array.from(labels).map((date) => data[date] || null),
          lineTension: 0.4,
          fill: false,
          id: binData.id,
          address: binData.address,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
        };
        datasets.push(dataset);
      }
    });

    return {
      labels: Array.from(labels),
      datasets: datasets,
    };
  };

  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [],
  });

  const [newOptions, setNewOptions] = useState(
    trashBinsEventsFromDateToDateList
      .map((item) => ({
        value: item.id,
        label: item.address,
        isFixed: true,
      }))
      .sort((a, b) => a.value - b.value)
  );

  // useEffect(() => {
  //   console.log("UseEffect selectedTrashBinId", selectedTrashBinId);
  // }, [selectedTrashBinId]);

  useEffect(() => {
    setNewOptions(
      trashBinsEventsFromDateToDateList
        .map((item) => ({
          value: item.id,

          // label: item.address,

          label: item.id + " - " + item.address,
        }))
        .sort((a, b) => a.value - b.value)
    );
  }, [trashBinsEventsFromDateToDateList]);

  // const exportToPDF = () => {
  //   const chartContainer = document.querySelector(".chart-container");

  //   html2canvas(chartContainer).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF();

  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

  //     pdf.save(t("reports.reports"));
  //   });
  // };

  const exportToPDF = async () => {
    const chartContainers = document.querySelectorAll(".chart-container");

    const pdfDoc = await PDFDocument.create();

    for (const chartContainer of chartContainers) {
      const canvas = await html2canvas(chartContainer);

      const page = pdfDoc.addPage([canvas.width, canvas.height]);

      const pngImage = await pdfDoc.embedPng(canvas.toDataURL("image/png"));
      const pngDims = pngImage.scale(1);

      page.drawImage(pngImage, {
        x: 0,
        y: 0,
        width: pngDims.width,
        height: pngDims.height,
      });
    }

    const pdfBytes = await pdfDoc.save();

    // Download the PDF
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    downloadLink.download = t("reports.reports");
    downloadLink.click();
  };

  useEffect(() => {
    resetFilters();
    initializeData();
    //setGraphData(defaultGraphData);
  }, []);

  useEffect(() => {
    //setGraphData(defaultGraphData);

    getTrashBinsEventsFromDateToDate(
      historicMapStartDate,
      historicMapEndDate
    ).then(() => {
      const newGraphData = initializeGraphData();
      // console.log("newGraphData", newGraphData);
      setGraphData(newGraphData);
    });
  }, [
    filteredHistoricTrashBinsFromDateToDateList?.length,
    shouldFetchHistoricMapData === true,
  ]);

  return (
    <div className="mt-6 chart-container">
      <div>
        <div className="flex items-center justify-end mr-2 mb-2">
          <AnimateButton>
            <SubmitButton
              sx={{ backgroundColor: colors.greenAccent.main }}
              className="capitalize text-base mr-2"
              buttonTitle="addComponent"
              style={{
                background: colors.greenAccent.main,
              }}
              disableRipple
              disableElevation
              type="submit"
              onClick={addNewChart}
            />
          </AnimateButton>
          <AnimateButton>
            <SubmitButton
              sx={{ backgroundColor: colors.greenAccent.main }}
              className="capitalize text-base"
              buttonTitle="exportToPdf"
              style={{
                background: colors.greenAccent.main,
              }}
              disableRipple
              disableElevation
              type="submit"
              onClick={exportToPDF}
            />
          </AnimateButton>
        </div>

        <>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            isClearable={isClearable}
            isSearchable={isSearchable}
            options={newOptions}
            onChange={handleOnChange}
            placeholder={t("trashBins.ChooseTrashbins")}
          />
          <div className="mt-[0.5rem]">
            {filteredHistoricTrashBinsFromDateToDateList?.length ? (
              <LineChart
                data={graphData}
                title={`${t(
                  "reports.SelectedDates"
                )}: ${formatDateInDateMonthYear(
                  historicMapStartDate
                )} - ${formatDateInDateMonthYear(historicMapEndDate)}`}
              />
            ) : (
              <NoDataCard />
            )}
          </div>
        </>

        {componentList?.map((component, index) => {
          return (
            <div className="mt-4" key={index}>
              <div className="flex justify-end items-end mb-2">
                <RemoveCircleIcon
                  fontSize="large"
                  color="primary"
                  className="cursor-pointer"
                  onClick={() => removeNewChart(index)}
                />
              </div>
              <div className="mt-[0.7rem]">
                <Select
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  options={newOptions}
                  onChange={(events) => handleAlternativeSelect(events, index)}
                  placeholder={t("trashBins.ChooseTrashbins")}
                />
              </div>
              <div className="mt-[0.5rem]">
                {/* {console.log("component graphData", component.graphData)} */}
                {component?.graphData?.datasets?.length ? (
                  <LineChart
                    key={index}
                    data={component?.graphData}
                    title={`${t(
                      "reports.SelectedDates"
                    )}: ${formatDateInDateMonthYear(
                      historicMapStartDate
                    )} - ${formatDateInDateMonthYear(historicMapEndDate)}`}
                  />
                ) : (
                  <NoDataCard />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(CumulativeMaxChart);
