import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import { createContext, useContext } from "react";
import { success } from "../Common/Toast/Toast";
import AuthSetAgent from "../Api/AuthSetAgent";
import { calculateTrashBinThresholdRange } from "../Common/Map/TrashBinThresholdRange/TrashBinThresholdRange";
import { formatDateInDateMonthYear } from "../Common/formatDate/formatDate";
import i18n from "../Config/Localization/i18n";
import { thresholdStore } from "./ThresholdStore";
import {
  highBinFullnessKey,
  lowBinFullnessKey,
  mediumBinFullnessKey,
  superLowBinFullnessKey,
} from "../Config/constant";
import { requests } from "../Common/HttpClient/HttpClient";

class MapStore {
  trashBinsPerThreshold = [];
  trashBinsEventsList = [];
  trashBinsEventsForDateList = [];
  trashBinsEventsFromDateToDateList = [];
  trashBinsList = [];
  trashBinsTypes = [];
  selectedTrashBinId = [];
  defaultHistoricMapStartDate = new Date(
    new Date().getTime() - 1 * 24 * 60 * 60 * 1000
  ); // 7 days from now;
  defaultHistoricMapEndDate = new Date();
  historicMapStartDate = this.defaultHistoricMapStartDate;
  historicMapEndDate = this.defaultHistoricMapEndDate;

  allFilter = true;
  resetAllFilter = false;

  allAlertsFilter = true;

  thresholdsFilter = [];

  textualInformation = [];

  // thresholdsFilter = [
  //   {
  //     id: "cap-25-full",
  //     title: "25% Full",
  //     value: "25%",
  //     status: true,
  //   },
  //   {
  //     id: "cap-50-full",
  //     title: "50% Full",
  //     value: "50%",
  //     status: true,
  //   },
  //   {
  //     id: "cap-75-full",
  //     title: "75% Full",
  //     value: "75%",
  //     status: true,
  //   },
  //   {
  //     id: "cap-100-full",
  //     title: "100% Full",
  //     value: "100l",
  //     status: true,
  //   },
  // ];

  alertFilter = [
    {
      id: 200,
      translationKey: "alerts.InnaccessibleDevice",
      type: "alert",
      status: true,
    },
    {
      id: 201,
      translationKey: "alerts.LowBattery",
      type: "alert",
      status: true,
    },
    {
      id: 202,
      translationKey: "alerts.Flipped",
      type: "alert",
      status: true,
    },
    {
      id: 203,
      translationKey: "alerts.FullTrash",
      type: "alert",
      status: true,
    },
    {
      id: 204,
      translationKey: "alerts.Fire",
      type: "alert",
      status: true,
    },
  ];

  shouldFetchHistoricMapData = false;

  constructor() {
    makeObservable(this, {
      trashBinsPerThreshold: observable,
      trashBinsEventsList: observable,
      textualInformation: observable,
      trashBinsEventsForDateList: observable,
      trashBinsEventsFromDateToDateList: observable,
      trashBinsList: observable,
      trashBinsTypes: observable,
      // defaultHistoricMapStartDate: observable,
      historicMapStartDate: observable,
      // defaultHistoricMapEndDate: observable,
      historicMapEndDate: observable,
      shouldFetchHistoricMapData: observable,
      selectedTrashBinId: observable,
      resetFilters: action,
      setHistoricMapStartDate: action,
      setHistoricMapEndDate: action,
      submitHistoricMapDates: action,
      getTrashBinsEvents: action,
      getTrashBins: action,
      getTrashBinsTypes: action,
      getThresholdsFilterData: action,
      allFilter: observable,
      resetAllFilter: observable,
      thresholdsFilter: observable,
      alertFilter: observable,
      allAlertsFilter: observable,
      initializeData: action,
      getTextualInformationFromDateToDate: action,
      filteredTrashBinsEventsList: computed,
      filteredHistoricTrashBinsForDateList: computed,
      filteredHistoricTrashBinsFromDateToDateList: computed,
      detectIsAllEnabled: action,
      toggleAllStatus: action,
      toggleResetAllStatus: action,
      toggleThresholdsStatus: action,
      toggleAlertStatus: action,
      toggleAllAlertsStatus: action,
      toggleTypeStatus: action,
      updateTrashBinStatus: action,
      submitTextualInformationMapDates: action,
      setSelectedTrashBinId: action,
      downloadTrashBinSample: action,
    });
    // this.initializeData();
  }

  initializeData = () => {
    this.getTrashBinsEvents();
    // this.getTrashBins();
    this.getTrashBinsTypes();
    this.getThresholdsFilterData();
  };

  resetFilters = () => {
    this.thresholdsFilter.map((elm) => (elm.status = true));
    this.alertFilter.map((elm) => (elm.status = true));
    this.selectedTrashBinId = [];
    this.detectIsAllEnabled();
  };

  getTrashBinsTypes = () => {
    AuthSetAgent.trashBins
      .getTrashBinTypes()
      .then((trashBinTypes) => {
        let newTrashBinTypesArr =
          trashBinTypes && trashBinTypes?.result ? trashBinTypes?.result : [];

        newTrashBinTypesArr?.map((elm) => {
          if (elm?.status === undefined) {
            elm.status = true;
          }
          if (elm?.type === undefined) {
            elm.type = "type";
          }
        });
        this.trashBinsTypes = newTrashBinTypesArr;
      })
      .catch((error) => {
        // console.log("getTrashBinTypes error", error);
      });
  };

  getThresholdsFilterData = () => {
    this.thresholdsFilter = [];
    thresholdStore
      .getThresholds()
      .then((thresholds) => {
        let superLowTrashBins = 0;
        let lowTrashBins = 0;
        let mediumTrashBins = 0;
        let highTrashBins = 0;

        this.trashBinsEventsList.map((bin) => {
          let myRange = calculateTrashBinThresholdRange(
            thresholdStore.thresholds,
            bin.binPercentageFullness
          );

          if (myRange === superLowBinFullnessKey) {
            superLowTrashBins++;
          } else if (myRange === lowBinFullnessKey) {
            lowTrashBins++;
          } else if (myRange === mediumBinFullnessKey) {
            mediumTrashBins++;
          } else if (myRange === highBinFullnessKey) {
            highTrashBins++;
          }
        });

        this.trashBinsPerThreshold.push(
          superLowBinFullnessKey,
          lowBinFullnessKey,
          mediumBinFullnessKey,
          highBinFullnessKey
        );

        const thresholdsArr = [
          {
            id: superLowBinFullnessKey,
            value: thresholds.superLowBinFullness,
            translationKey: "thresholds.superLowBinFullness",
            totalTrashBins: superLowTrashBins,
            status: true,
          },
          {
            id: lowBinFullnessKey,
            value: thresholds.lowBinFullness,
            translationKey: "thresholds.lowBinFullness",
            totalTrashBins: lowTrashBins,
            status: true,
          },
          {
            id: mediumBinFullnessKey,
            value: thresholds.mediumBinFullness,
            translationKey: "thresholds.mediumBinFullness",
            totalTrashBins: mediumTrashBins,
            status: true,
          },
          {
            id: highBinFullnessKey,
            value: thresholds.highBinFullness,
            translationKey: "thresholds.highBinFullness",
            totalTrashBins: highTrashBins,
            status: true,
          },
        ];
        this.thresholdsFilter = thresholdsArr;
      })
      .catch((error) => {});
  };

  setSelectedTrashBinId = (trashBinIds) => {
    if (trashBinIds?.length > 0) {
      this.selectedTrashBinId = trashBinIds;
      // console.log(
      //   "setSelectedTrashBinId has length",
      //   toJS(this.selectedTrashBinId)
      // );
    } else {
      this.selectedTrashBinId = [];
      // console.log(
      //   "setSelectedTrashBinId does not have length",
      //   this.selectedTrashBinId
      // );
    }
  };

  getTrashBinsEvents = () => {
    AuthSetAgent.trashBins
      .getTrashBinsEvents()
      .then((trashBinsEvents) => {
        this.trashBinsEventsList =
          trashBinsEvents && trashBinsEvents?.result
            ? trashBinsEvents.result
            : [];
      })
      .catch((error) => {
        // console.log("getTrashBinsEvents error", error);
      });
  };

  getTrashBinsEventsForDate = (startDate) => {
    let formatedStartDate = formatDateInDateMonthYear(startDate);
    // console.log("formatedStartDate", formatedStartDate);

    return AuthSetAgent.trashBins
      .getTrashBinsEventsForDate(formatedStartDate)
      .then((trashBinsEventsForDate) => {
        this.trashBinsEventsForDateList = trashBinsEventsForDate
          ? trashBinsEventsForDate.result
          : [];

        // console.log("trashBinsEventsForDate resp", this.trashBinsEventsForDate);
      })
      .catch((error) => {
        // console.log("getTrashBinsEventsForDate error", error);
      });
  };

  getTrashBinsEventsFromDateToDate = (startDate, endDate) => {
    let formatedStartDate = formatDateInDateMonthYear(startDate);
    let formatedEndDate = formatDateInDateMonthYear(endDate);
    // console.log(
    //   "formatedStartDate",
    //   formatedStartDate,
    //   " | ",
    //   "formatedEndDate",
    //   formatedEndDate
    // );

    return AuthSetAgent.trashBins
      .getTrashBinsEventsFromDateToDate(formatedStartDate, formatedEndDate)
      .then((trashBinsEventsFromDateToDate) => {
        this.trashBinsEventsFromDateToDateList = trashBinsEventsFromDateToDate
          ? trashBinsEventsFromDateToDate.result
          : [];
      })
      .catch((error) => {
        // console.log("getTrashBinsEventsFromDateToDate error", error);
      });
  };

  getTrashBins = () => {
    AuthSetAgent.trashBins
      .getTrashBins()
      .then((trashBins) => {
        // let newTrashBinsArr = trashBins ? trashBins : [];
        // // console.log("trash bins", toJS(this.trashBinsList));

        // // TODO: This will need to be commented out / removed when the RESP API will provide it
        // newTrashBinsArr[0].thresholdValue = "25% Full";
        // newTrashBinsArr[1].thresholdValue = "50% Full";
        // newTrashBinsArr[2].thresholdValue = "75% Full";
        this.trashBinsList =
          trashBins && trashBins?.result ? trashBins.result : [];
      })
      .catch((error) => {
        // console.log("getTrashBins error", error);
      });
  };

  // Called on main map
  get filteredTrashBinsEventsList() {
    let filteredTrashBins = this.trashBinsEventsList?.filter((bin) => {
      // Find the matching bin type in trashBinsTypes
      let binType = this.trashBinsTypes?.find(
        (type) => type.id === bin.trashBinTypeId
      );

      const thresholdRange = calculateTrashBinThresholdRange(
        thresholdStore.thresholds,
        bin.binPercentageFullness
      );

      let binThreshold = this.thresholdsFilter?.find(
        (threshold) => threshold.id === thresholdRange
      );

      let hasTypesCond = binType && binType.status;
      let hasThresholdCond = binThreshold && binThreshold.status;

      let hasAlertCond = this.alertFilter.status;

      if (
        bin.timeLocationActiveAlerts &&
        bin.timeLocationActiveAlerts?.length > 0
      ) {
        // console.log(
        //   "bin.timeLocationActiveAlerts",
        //   toJS(bin.timeLocationActiveAlerts)
        // );

        // Find the matching alert object in alertFilter array
        let binAlert = bin.timeLocationActiveAlerts.find((elm) => {
          // console.log("elm.alertCode", toJS(elm.alertCode));

          let alert = this.alertFilter.find((a) => a.id === elm.alertCode);
          // console.log("alert", toJS(alert));

          return alert && alert.status;
        });

        // console.log("binAlert", toJS(binAlert));
        hasAlertCond = binAlert !== undefined;
        return hasTypesCond && hasThresholdCond && hasAlertCond;
      } else {
        return hasTypesCond && hasThresholdCond;
      }
    });
    // console.log("filteredTrashBins", JSON.stringify(filteredTrashBins));

    return filteredTrashBins?.length > 0 ? filteredTrashBins : [];
  }

  get filteredHistoricTrashBinsForDateList() {
    this.shouldFetchHistoricMapData = false;

    let filteredTrashBins = this.trashBinsEventsForDateList?.filter((bin) => {
      let hasBinStatusCond = bin.status === 1;

      let binType = this.trashBinsTypes?.find(
        (type) => type.id === bin.trashBinTypeId
      );

      //TODO: must be tested when BE sends correct binPercentageFullness between 0 & 100 Otherwise the calculateTrashBinThresholdRange will remove elements exceeding that amount.
      // TODO remove division when endpoint sends correct binPercentageFullness
      const thresholdRange = calculateTrashBinThresholdRange(
        thresholdStore.thresholds,
        bin.telemetryTrashBinEvents?.length > 0
          ? bin.telemetryTrashBinEvents[bin.telemetryTrashBinEvents?.length - 1]
              .binPercentageFullness
          : []
      );

      let binThreshold = this.thresholdsFilter?.find(
        (threshold) => threshold.id === thresholdRange
      );

      let hasTypesCond = binType && binType.status;
      let hasThresholdCond = binThreshold && binThreshold.status;

      // return hasBinStatusCond && hasTypesCond && hasThresholdCond;

      return hasTypesCond && hasThresholdCond;
    });
    // console.log(
    //   "filteredHistoricTrashBinsForDateList",
    //   JSON.stringify(filteredTrashBins)
    // );
    // console.log(
    //   "filteredHistoricTrashBinsForDateList",
    //   filteredTrashBins?.length
    // );

    return filteredTrashBins?.length > 0 ? filteredTrashBins : [];
  }

  get filteredHistoricTrashBinsFromDateToDateList() {
    this.shouldFetchHistoricMapData = false;

    let filteredTrashBins = this.trashBinsEventsFromDateToDateList?.filter(
      (bin) => {
        // let hasBinStatusCond = bin.status === 1;

        let binType = this.trashBinsTypes?.find(
          (type) => type.id === bin.trashBinTypeId
        );

        //TODO: must be tested when BE sends correct binPercentageFullness between 0 & 100 Otherwise the calculateTrashBinThresholdRange will remove elements exceeding that amount.
        // TODO remove division when endpoint sends correct binPercentageFullness
        const thresholdRange = calculateTrashBinThresholdRange(
          thresholdStore.thresholds,
          bin.telemetryTrashBinEvents?.length > 0
            ? bin.telemetryTrashBinEvents[
                bin.telemetryTrashBinEvents?.length - 1
              ].binPercentageFullness
            : []
        );

        let binThreshold = this.thresholdsFilter?.find(
          (threshold) => threshold.id === thresholdRange
        );

        let hasTypesCond = binType && binType.status;
        let hasThresholdCond = binThreshold && binThreshold.status;

        // return hasBinStatusCond && hasTypesCond && hasThresholdCond;
        // return hasTypesCond && hasThresholdCond; // As of 28-04-2023

        return hasTypesCond && hasThresholdCond;
      }
    );
    // console.log("filteredTrashBins", JSON.stringify(filteredTrashBins));
    //console.log(
    //   "filteredHistoricTrashBinsFromDateToDateList",
    //   filteredTrashBins?.length
    // );

    if (this.selectedTrashBinId.length > 0) {
      return filteredTrashBins.filter((trashBin) =>
        this.selectedTrashBinId.some((item) => item.value === trashBin.id)
      );
    } else {
      return filteredTrashBins?.length > 0 ? filteredTrashBins : [];
    }
    // return filteredTrashBins?.length > 0 ? filteredTrashBins : [];
  }

  setHistoricMapStartDate = (date) => {
    if (date && date instanceof Date && !isNaN(date.getTime())) {
      this.historicMapStartDate = date;
    }
  };

  setHistoricMapEndDate = (date) => {
    if (date && date instanceof Date && !isNaN(date.getTime())) {
      this.historicMapEndDate = date;
    }
  };

  submitHistoricMapDates = (hasStartDate, hasEndDate) => {
    if (hasStartDate === true && hasEndDate === false) {
      return this.getTrashBinsEventsForDate(this.historicMapStartDate)
        .then((resp) => {
          this.shouldFetchHistoricMapData = true;
        })
        .catch((error) => {
          this.shouldFetchHistoricMapData = false;
        });
    } else if (hasStartDate === true && hasEndDate === true) {
      return this.getTrashBinsEventsFromDateToDate(
        this.historicMapStartDate,
        this.historicMapEndDate
      )
        .then((resp) => {
          this.shouldFetchHistoricMapData = true;
        })
        .catch((error) => {
          this.shouldFetchHistoricMapData = false;
        });
    }
  };

  getTextualInformationFromDateToDate = (startDate, endDate) => {
    let formatedStartDate = formatDateInDateMonthYear(startDate);
    let formatedEndDate = formatDateInDateMonthYear(endDate);

    return AuthSetAgent.textualInformation
      .getTextualInformationFromDateToDate(formatedStartDate, formatedEndDate)
      .then((textualInformationFromDateToDate) => {
        this.textualInformation = textualInformationFromDateToDate
          ? textualInformationFromDateToDate?.result
          : [];
      })
      .catch((error) => {});
  };

  submitTextualInformationMapDates = (hasStartDate, hasEndDate) => {
    if (hasStartDate === true && hasEndDate === true) {
      return this.getTextualInformationFromDateToDate(
        this.historicMapStartDate,
        this.historicMapEndDate
      )
        .then((resp) => {
          this.shouldFetchHistoricMapData = true;
          // console.log("resp", resp);
        })
        .catch((error) => {
          this.shouldFetchHistoricMapData = false;
        });
    }
  };

  getTrashBinEventsById = (id) =>
    AuthSetAgent.trashBins.getTrashBinEventsById(id);

  getTrashBinById = (id) => AuthSetAgent.trashBins.getTrashBinById(id);

  activateTrashBinById = (id) => AuthSetAgent.trashBins.activateTrashBin(id);

  deactivateTrashBinById = (id) =>
    AuthSetAgent.trashBins.deactivateTrashBin(id);

  createNewTrashBin(data) {
    return AuthSetAgent.trashBins.createTrashBin(data);
  }

  updateTrashBin(trashBinId, data) {
    return AuthSetAgent.trashBins.updateTrashBins(trashBinId, data);
  }

  downloadTrashBinSample = async () => {
    const apiUrl = "/TrashBin/download-sample";
    const response = await requests.getFile(apiUrl, "blob");
    const blob = response.data;
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "trashbin_sample.xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    reaction(
      () => URL.revokeObjectURL(url),
      (revoked) => {
        if (revoked) {
        }
      }
    );
  };

  // deleteTrashBin(trashBinId) {
  //   return new Promise((resolve, reject) => {
  //     AuthSetAgent.trashBins
  //       .deleteTrashBinById(trashBinId)
  //       .then((response) => {
  //         const trashBinIndex = this.trashBinsList.findIndex(
  //           (s) => s.id === trashBinId
  //         );
  //         this.trashBinsList.splice(trashBinIndex, 1);
  //         resolve(response);
  //       })
  //       .catch((e) => {
  //         reject(e);
  //       });
  //   });
  // }

  // deleteTrashBinById(trashBinId).then((response) => {
  //   const trashBinIndex = this.trashBinsList.findIndex(
  //     (s) => s.id === trashBinId
  //   );
  //   this.trashBinsList.splice(trashBinIndex, 1);
  //   return("trashBins.TrashBinDeletedSuccess")
  // }).catch((e) => {
  // im not doing anything at all
  // });

  detectIsAllEnabled = () => {
    const thresholdsStatus = this.thresholdsFilter.every(
      (el) => el.status === true
    );
    const typeStatus = this.trashBinsTypes.every((el) => el.status === true);
    const alertStatus = this.alertFilter.every((el) => el.status === true);

    this.allAlertsFilter = alertStatus;
    this.allFilter =
      thresholdsStatus === true && typeStatus === true && alertStatus === true;

    // Enable for debugging
    // console.log("trashBinsList", toJS(this.trashBinsList))
    // console.log(
    //   "filteredTrashBinsEventsList",
    //   JSON.stringify(this.filteredTrashBinsEventsList)
    // );
  };

  toggleAllStatus = () => {
    this.allFilter = !this.allFilter;

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.thresholdsFilter.length; index++) {
      this.thresholdsFilter[index].status = this.allFilter;
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.trashBinsTypes.length; index++) {
      this.trashBinsTypes[index].status = this.allFilter;
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.alertFilter.length; index++) {
      this.alertFilter[index].status = this.allFilter;
    }

    this.detectIsAllEnabled();
  };

  toggleResetAllStatus = (hasStartDate, hasEndDate) => {
    this.resetAllFilter = true;

    this.historicMapStartDate = this.defaultHistoricMapStartDate;
    this.historicMapEndDate = this.defaultHistoricMapEndDate;

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.thresholdsFilter.length; index++) {
      this.thresholdsFilter[index].status = this.resetAllFilter;
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.trashBinsTypes.length; index++) {
      this.trashBinsTypes[index].status = this.resetAllFilter;
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.alertFilter.length; index++) {
      this.alertFilter[index].status = this.resetAllFilter;
    }

    this.resetAllFilter = false;

    this.submitHistoricMapDates(hasStartDate, hasEndDate);

    this.detectIsAllEnabled();
  };

  toggleThresholdsStatus = (thresholdId) => {
    const curElm = this.thresholdsFilter?.find((o) => o.id === thresholdId);
    curElm.status = !curElm.status;

    this.detectIsAllEnabled();
    // console.log("this.threshold", toJS(this.thresholdsFilter));
  };

  toggleAlertStatus = (alertId) => {
    const curElm = this.alertFilter?.find((o) => o.id === alertId);
    curElm.status = !curElm.status;

    this.detectIsAllEnabled();
  };

  toggleAllAlertsStatus = () => {
    this.allAlertsFilter = !this.allAlertsFilter;

    for (let index = 0; index < this.alertFilter.length; index++) {
      this.alertFilter[index].status = this.allAlertsFilter;
    }

    this.detectIsAllEnabled();
  };

  toggleTypeStatus = (typeId) => {
    const curElm = this.trashBinsTypes?.find((o) => o.id === typeId);

    if (typeId === 1) {
      const generalWasteSmall = this.trashBinsTypes?.find((o) => o.id === 9);
      if (generalWasteSmall) {
        generalWasteSmall.status = !generalWasteSmall.status;
      }
    }

    curElm.status = !curElm.status;

    this.detectIsAllEnabled();
  };

  updateTrashBinStatus = (id) => {
    const curElm = this.trashBinsEventsList?.find((o) => o.id === id);
    // console.log('curElm', curElm);
    if (curElm.status === 0) {
      AuthSetAgent.trashBins.activateTrashBin(id).then((e) => {
        success(i18n.t("trashBins.TrashBinHasBeenEnabled"));
      });
    } else {
      AuthSetAgent.trashBins.deactivateTrashBin(id).then((e) => {
        success(i18n.t("trashBins.TrashBinHasBeenDisabled"));
      });
    }
    curElm.status = !curElm.status;
    // console.log("this.trashBinsEventsList", toJS(this.trashBinsEventsList));
  };
}

export const mapStore = new MapStore();
export const MapStoreContext = createContext(mapStore);
export const MapStoreProvider = ({ children, store }) => (
  <MapStoreContext.Provider value={store}>{children}</MapStoreContext.Provider>
);
export const useMapStore = () => useContext(MapStoreContext);
