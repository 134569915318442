import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { observer } from "mobx-react";
import { useEffect } from "react";

const LineChart = ({ data, title = "", options }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const initOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: title && title !== "" ? true : false,
        text: title,
      },
    },
  };

  const [chartHeight, setChartHeight] = useState(window.innerHeight * 0.65);

  useEffect(() => {
    const handleResize = () => setChartHeight(window.innerHeight * 0.65);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Line
        options={options ? options : initOptions}
        data={data}
        height={chartHeight}
      />
    </>
  );
};

export default observer(LineChart);
