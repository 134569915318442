import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAuthStore } from "../Stores/AuthStore";
import { observer } from "mobx-react";
import Loadable from "../Components/Theme/Loadable";
import { lazy } from "react";
import Reports from "../Pages/Authenticated/Reports/Reports";
import MapReport from "../Pages/FullnessHistory/MapReport.js";
import FullnessHistory from "../Pages/FullnessHistory/FullnessHistory";
import TableReport from "../Pages/FullnessHistory/TableReport";
import PeriodicFullnessChart from "../Pages/Authenticated/Reports/PeriodicFullnessChart";
import CumulativeMaxChart from "../Pages/Authenticated/Reports/CumulativeMaxChart";

import { useEffect } from "react";
import { alertStore } from "../Stores/AlertStore";

const Map = Loadable(lazy(() => import("../Pages/Authenticated/Map/Map")));

const NoAccessCard = Loadable(
  lazy(() => import("../Components/NoAccessCard/NoAccessCard"))
);

const NoDataCard = Loadable(
  lazy(() => import("../Components/NoDataCard/NoDataCard"))
);

const TrashBinsList = Loadable(
  lazy(() => import("../Pages/Authenticated/TrashBins/TrashBinsList"))
);
const TrashBinCreate = Loadable(
  lazy(() => import("../Pages/Authenticated/TrashBins/TrashBinsCreate"))
);

const TrashBinEdit = Loadable(
  lazy(() => import("../Pages/Authenticated/TrashBins/TrashBinsEdit"))
);
const TrashBinDetailTabs = Loadable(
  lazy(() => import("../Pages/Authenticated/TrashBins/TrashBinsDetailsTabs"))
);

const TrashBinsDevicesList = Loadable(
  lazy(() =>
    import("../Pages/Authenticated/TrashBinsDevices/TrashBinsDevicesList")
  )
);
const TrashBinDevicesCreate = Loadable(
  lazy(() =>
    import("../Pages/Authenticated/TrashBinsDevices/TrashBinsDevicesCreate")
  )
);

const TrashBinDevicesEdit = Loadable(
  lazy(() =>
    import("../Pages/Authenticated/TrashBinsDevices/TrashBinsDevicesEdit")
  )
);
const TrashBinDevicesDetailTabs = Loadable(
  lazy(() =>
    import(
      "../Pages/Authenticated/TrashBinsDevices/TrashBinsDevicesDetailsTabs"
    )
  )
);

const ProfileTabs = Loadable(
  lazy(() => import("../Pages/Authenticated/Profile/ProfileTabs"))
);
// const Profile = Loadable(
//   lazy(() => import("../Pages/Authenticated/Profile/Profile"))
// );
// const VerifyEmail = Loadable(
//   lazy(() => import("../Pages/Authenticated/Profile/VerifyEmail"))
// );

const Users = Loadable(
  lazy(() => import("../Pages/Authenticated/Users/UsersList"))
);
const UsersCreate = Loadable(
  lazy(() => import("../Pages/Authenticated/Users/UsersCreate"))
);
const EditUsers = Loadable(
  lazy(() => import("../Pages/Authenticated/Users/UsersEdit"))
);

const UsersDetailsTabs = Loadable(
  lazy(() => import("../Pages/Authenticated/Users/UsersDetailsTabs"))
);

const Threshold = Loadable(
  lazy(() => import("../Pages/Authenticated/Threshold/ThresholdPage"))
);

const Alert = Loadable(
  lazy(() => import("../Pages/Authenticated/Alert/AlertList"))
);

const AuthenticatedRoutes = () => {
  const authStore = useAuthStore();
  useEffect(() => {
    alertStore.initHubConnection();

    return () => {
      alertStore.connection.stop();
      alertStore.clearAlerts();
    };
  }, []);

  return (
    <Routes>
      <Route path="/profile" element={<ProfileTabs />} />
      <Route path="/noAccessCard" element={<NoAccessCard />} />
      <Route path="/noDataCard" element={<NoDataCard />} />

      {(authStore.isUser || authStore.authUserIsNotLoaded) && (
        <>
          <Route path="/" element={<Map />} />

          <Route path="/trashBin" element={<Outlet />}>
            <Route path="/trashBin" element={<TrashBinsList />} />
            <Route path="create" element={<TrashBinCreate />} />
            <Route path=":id/edit" element={<TrashBinEdit />} />
            <Route path=":id" element={<TrashBinDetailTabs />} />
          </Route>

          <Route path="/trashBinDevice" element={<Outlet />}>
            <Route path="/trashBinDevice" element={<TrashBinsDevicesList />} />
            <Route path="create" element={<TrashBinDevicesCreate />} />
            <Route path=":id/edit" element={<TrashBinDevicesEdit />} />
            <Route path=":id" element={<TrashBinDevicesDetailTabs />} />
          </Route>

          <Route path="/threshold" element={<Threshold />}></Route>

          <Route path="/alert" element={<Alert />}></Route>
          <Route path="/reports" element={<Outlet />}>
            <Route path="/reports" element={<Reports />} />
            <Route
              path="periodicFullnessChart"
              element={<PeriodicFullnessChart />}
            />
            <Route path="cumulativeMaxChart" element={<CumulativeMaxChart />} />
          </Route>

          <Route path="/fullnessHistory" element={<Outlet />}>
            <Route path="/fullnessHistory" element={<FullnessHistory />} />
            <Route path="mapReport" element={<MapReport />} />
            <Route path="tableReport" element={<TableReport />} />
          </Route>
        </>
      )}

      {(authStore.isAdmin || authStore.authUserIsNotLoaded) && (
        <>
          <Route path="/" element={<Users />} />

          <Route path="/users" element={<Outlet />}>
            <Route path="/users" element={<Users />} />
            <Route path="create" element={<UsersCreate />} />
            <Route path=":id/edit" element={<EditUsers />} />
            <Route path=":id" element={<UsersDetailsTabs />} />
          </Route>
        </>
      )}

      <Route path="*" element={<Navigate to="/noAccessCard" />} />
    </Routes>
  );
};
export default observer(AuthenticatedRoutes);
