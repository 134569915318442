import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
// material-ui
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// import { drawerWidth } from '../Config/constant';
// import Header from './Header';
// import { useThemeStore } from '../Stores/ThemeStore';
// import Sidebar from './Sidebar';
// import { menuItems } from './Sidebar/menu-items';

import { useAuthStore } from "../Stores/AuthStore";
import Icons from "../assets/icons";
import SidebarMenu from "../Components/SidebarMenu/SidebarMenu";
// import Topbar from "../Components/Topbar/Topbar";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: "20px",
        marginRight: "20px",
        width: `84%`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `84%`,
        padding: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        width: `84%`,
        padding: "16px",
        marginRight: "10px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `84%`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const AuthenticatedContent = ({ children }) => {
  const authStore = useAuthStore();
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SidebarMenu />

      {/* main content */}
      <Main theme={theme}>
        {/* <Toolbar className="py-0 px-0"><Topbar /></Toolbar> */}
        {children}
      </Main>
    </Box>
  );
};

export default observer(AuthenticatedContent);
