import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLanguageStore } from '../../Stores/LanguageStore';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
    const languageStore = useLanguageStore();
    const { t } = useTranslation();

    const handleChange = (event) => {
        languageStore.changeLanguage(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 100, m: 1 }}>
            <FormControl fullWidth variant="standard">
                <InputLabel id="language-switcher">{t('locale.Language')}</InputLabel>
                <Select labelId="language-switcher" id="language-switcher" value={languageStore.language} onChange={handleChange}>
                    <MenuItem value="en">{t('locale.English')}</MenuItem>
                    <MenuItem value="el">{t('locale.Greek')}</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export default observer(LanguageSwitcher);
