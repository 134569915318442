import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';

import AuthenticatedContent from './AuthenticatedContent';
import GuestContent from './GuestContent';
import GuestRoutes from '../Routes/GuestRoutes';
import AuthenticatedRoutes from '../Routes/AuthenticatedRoutes';
import { useAuthStore } from '../Stores/AuthStore';

const AppContent = () => {
    const authStore = useAuthStore();

    return (
        <>
            <BrowserRouter>
                {authStore.userAuthenticated ? (
                    <AuthenticatedContent>
                        <AuthenticatedRoutes />
                    </AuthenticatedContent>
                ) : (
                    <GuestContent>
                        <GuestRoutes />
                    </GuestContent>
                )}
            </BrowserRouter>
        </>
    );
};

export default observer(AppContent);
