import { action, makeObservable, observable, reaction } from "mobx";
import { createContext, useContext } from "react";
import i18n from "../Config/Localization/i18n";
import { defaultLanguage } from "../Config/constant";
import {
  saveLanguageToSessionStorage,
  getLanguageFromSessionStorage,
} from "../Common/Storage/SessionStorage";

class LanguageStore {
  language = null;

  constructor() {
    makeObservable(this, {
      language: observable,
      setInitialLanguage: action,
      changeLanguage: action,
    });

    reaction(
      () => this.language,
      (newLanguage) => {
        saveLanguageToSessionStorage(newLanguage);
        i18n.changeLanguage(newLanguage);
      }
    );

    this.setInitialLanguage();
  }

  setInitialLanguage() {
    const localeStorageLanguage = getLanguageFromSessionStorage();
    this.language = localeStorageLanguage ?? defaultLanguage;
  }

  changeLanguage(language) {
    this.language = language;
  }
}

export const languageStore = new LanguageStore();
export const LanguageStoreContext = createContext(languageStore);
export const useLanguageStore = () => useContext(LanguageStoreContext);
