import { Navigate, Route, Routes, useLocation, Outlet } from "react-router-dom";
import LoginPage from "../Pages/Guest/LoginPage";
import ForgotPasswordPage from "../Pages/Guest/ForgotPasswordPage";
import ResetPasswordPage from "../Pages/Guest/ResetPasswordPage";

const GuestRoutes = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route exact path="/" element={<Outlet />}>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          exact
          path="/reset-password/:token"
          element={<ResetPasswordPage />}
        />
      </Route>

      <Route
        path="*"
        element={<Navigate to="/" state={{ from: location }} />}
      />
    </Routes>
  );
};

export default GuestRoutes;
