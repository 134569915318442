import { InfoWindow, MarkerClusterer } from "@react-google-maps/api";
import React from "react";
import { mapStore } from "../../../../Stores/MapStore";
import {
  Tooltip,
  Typography,
  Modal,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import i18n from "../../../../Config/Localization/i18n";
import MainCard from "../../../Theme/Cards/MainCard";
import Icons from "../../../../assets/icons";
import RoundIcon from "../../../RoundIcon/RoundIcon";
import { Visibility } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { toJS } from "mobx";
import { formatDateInDateMonthYearHour } from "../../../../Common/formatDate/formatDate";

const TrashbinInfoWindow = (
  setSelectedTrashBinId,
  navigate,
  isTrashBinModalOpen,
  setIsTrashBinModalOpen,
  isDeviceBinModalOpen,
  setIsDeviceBinModalOpen,
  marker,
  onClose
) => {
  const verticalPositioning = 0.014;

  const binPercentageFullness = () => {
    return marker?.binPercentageFullness !== undefined
      ? marker?.binPercentageFullness + "%"
      : "-";
  };

  const graphNavigation = (marker) => {
    setSelectedTrashBinId(marker);
    // console.log("In TrashBinInfoWindow graphNavigaiton marker: ", toJS(marker));
    navigate("/reports");
  };

  const handleOpenTrashBinModal = () => {
    setIsTrashBinModalOpen(true);
  };

  const handleCloseTrashBinModal = () => {
    setIsTrashBinModalOpen(false);
  };

  const handleOpenDeviceBinModal = () => {
    setIsDeviceBinModalOpen(true);
  };

  const handleCloseDeviceBinModal = () => {
    setIsDeviceBinModalOpen(false);
  };

  const CustomLinearProgress = styled(LinearProgress)(({ value }) => ({
    height: "5px",
    width: "70%",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor:
        value >= 0 && value <= 25
          ? "#168D8B" // green for values between 0 and 25
          : value > 25 && value <= 50
          ? "#EFDB22" // yellow for values between 25 and 50
          : value > 50 && value <= 75
          ? "#FF7D00" // orange for values between 50 and 75
          : value > 75 && value <= 100
          ? "#D00404" // red for values between 75 and 100
          : undefined, // undefined if value is not within 0-100
    },
  }));

  return (
    <>
      <InfoWindow
        position={{
          // lat: marker.lat + verticalPositioning,
          lat: marker.lat,
          lng: marker.lon,
        }}
        onClick={onClose}
      >
        <div>
          <div className="flex items-center p-1 space-y-0.5">
            <Typography>{i18n.t("trashBins.TrashBinTypes")} :</Typography>
            <div className="bg-green-600 hover:bg-green-700 text-white text-sm px-4  border rounded-full uppercase ml-2 mb-4">
              {
                mapStore.trashBinsTypes.find(
                  (value) => value.id === marker.trashBinTypeId
                )?.description
              }
            </div>
          </div>

          <div className="flex items-center  p-1 space-y-0.5">
            <Typography>{i18n.t("trashBins.Name")} :</Typography>
            <div className="font-semibold text-sm ml-2 mb-4">
              {marker?.id + " - " + marker?.name}
            </div>
            <Tooltip onClick={handleOpenTrashBinModal}>
              <Visibility
                className="ml-2 cursor-pointer"
                sx={{ fontSize: "1.1rem", color: "#60C15C" }}
              />
            </Tooltip>
          </div>

          <div className="flex items-center  p-1 space-y-0.5">
            <Typography>
              {i18n.t("trashBinDevices.TrashBinDevices")} :
            </Typography>
            <div className="font-semibold text-sm ml-2 mb-4">
              {marker?.device?.nativeId ? marker?.device?.nativeId : "-"}
            </div>
            {marker?.device?.nativeId ? (
              <Tooltip onClick={handleOpenDeviceBinModal}>
                <Visibility
                  className="ml-2 cursor-pointer"
                  sx={{ fontSize: "1.1rem", color: "#60C15C" }}
                />
              </Tooltip>
            ) : null}
          </div>

          <div className="flex items-center  p-1 space-y-0.5">
            <Typography>{i18n.t("trashBins.TrashBinFullness")} :</Typography>
            <div className="font-semibold text-sm ml-2 mb-4">
              {marker?.telemetryTrashBinEvents === undefined
                ? binPercentageFullness()
                : null}
              {/* This is needed for main Map */}

              {marker?.binPercentageFullness === undefined
                ? marker.telemetryTrashBinEvents?.length > 0
                  ? marker.telemetryTrashBinEvents[0].binPercentageFullness +
                    "%"
                  : "-"
                : null}
              {/* This is needed for historic map Map */}
            </div>
            <Tooltip onClick={() => graphNavigation(marker)}>
              <Visibility
                className="ml-2 cursor-pointer"
                sx={{ fontSize: "1.1rem", color: "#60C15C" }}
              />
            </Tooltip>
          </div>

          <div className="flex items-center  p-1 space-y-0.5">
            <Typography>{i18n.t("trashBins.LastMeasurement")} :</Typography>
            <div className="font-semibold text-sm ml-2 mb-4">
              {marker?.timeStamp
                ? formatDateInDateMonthYearHour(marker?.timeStamp)
                : "-"}
            </div>
          </div>

          <div className="flex items-center  p-1 space-y-0.5">
            <Typography>{i18n.t("trashBins.Address")} :</Typography>
            <div className="font-semibold text-sm ml-2 mb-4">
              {marker?.address}
            </div>
          </div>

          <div className="flex items-center  p-1 space-y-0.5">
            <Typography>{i18n.t("trashBins.Description")} :</Typography>
            <div className="font-semibold text-sm ml-2 mb-4">
              {marker?.description}
            </div>
          </div>
        </div>
      </InfoWindow>

      <>
        <Modal
          className="flex items-center justify-center"
          open={isTrashBinModalOpen}
          onClose={handleCloseTrashBinModal}
        >
          <MainCard
            className="m-4 min-w-[40vw] min-h-[25vw]"
            title={
              <div className="flex items-center justify-between">
                <Typography variant="h4">
                  {i18n.t("trashBins.TrashBinsDetails")}
                </Typography>

                <div
                  className="cursor-pointer"
                  onClick={handleCloseTrashBinModal}
                >
                  <Icons name="close" width={23} height={23} />
                </div>
              </div>
            }
          >
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="trashBinType" hover>
                    <TableCell variant="head">
                      {i18n.t("trashBins.TrashBinTypes")}
                    </TableCell>
                    <TableCell>
                      <Typography
                        align="left"
                        variant="subtitle1"
                        component="div"
                        className="flex items-center"
                      >
                        {marker?.trashBinTypeId ? (
                          <RoundIcon
                            type={"type"}
                            id={marker?.trashBinTypeId}
                          />
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow key="address" hover>
                    <TableCell variant="head">
                      {i18n.t("trashBins.Address")}
                    </TableCell>
                    <TableCell>{marker?.address}</TableCell>
                  </TableRow>

                  <TableRow key="name" hover>
                    <TableCell variant="head">
                      {i18n.t("trashBins.Name")}
                    </TableCell>
                    <TableCell>{marker?.name}</TableCell>
                  </TableRow>

                  <TableRow key="description" hover>
                    <TableCell variant="head">
                      {i18n.t("trashBins.Description")}
                    </TableCell>
                    <TableCell>{marker?.description}</TableCell>
                  </TableRow>

                  <TableRow key="deviceId" hover>
                    <TableCell variant="head">
                      {i18n.t("trashBinDevices.TrashBinDevices")}
                    </TableCell>
                    <TableCell>
                      {marker?.device?.nativeId
                        ? marker?.device?.nativeId
                        : "-"}
                    </TableCell>
                  </TableRow>

                  <TableRow key="size" hover>
                    <TableCell variant="head">
                      {i18n.t("trashBins.TrashBinFullness")}
                    </TableCell>
                    <TableCell>
                      {marker?.telemetryTrashBinEvents === undefined && (
                        <>
                          <Typography className="text-[#666666]">
                            {binPercentageFullness()}
                          </Typography>
                          <CustomLinearProgress
                            variant="determinate"
                            value={marker?.binPercentageFullness}
                            valueBuffer={marker?.binPercentageFullness}
                          />
                        </>
                      )}

                      {marker?.binPercentageFullness === undefined &&
                      marker?.telemetryTrashBinEvents?.length > 0 &&
                      marker?.telemetryTrashBinEvents[0]
                        ?.binPercentageFullness ? (
                        <>
                          <Typography className="text-[#666666]">
                            {
                              marker.telemetryTrashBinEvents[0]
                                .binPercentageFullness
                            }
                            %
                          </Typography>
                          <CustomLinearProgress
                            variant="determinate"
                            value={
                              marker.telemetryTrashBinEvents[0]
                                .binPercentageFullness
                            }
                            valueBuffer={
                              marker.telemetryTrashBinEvents[0]
                                .binPercentageFullness
                            }
                          />
                        </>
                      ) : null}
                      {/* This is needed for historic map Map */}
                    </TableCell>
                  </TableRow>

                  <TableRow key="capacity" hover>
                    <TableCell variant="head">
                      {i18n.t("trashBins.Capacity")}
                    </TableCell>
                    <TableCell>
                      {marker?.type?.capacity ? marker?.type?.capacity : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </MainCard>
        </Modal>
        <>
          <Modal
            className="flex items-center justify-center"
            open={isDeviceBinModalOpen}
            onClose={handleCloseDeviceBinModal}
          >
            <MainCard
              className="m-4 min-w-[40vw] min-h-[20vw]"
              title={
                <div className="flex items-center justify-between">
                  <Typography variant="h4">
                    {i18n.t("trashBinDevices.TrashBinDevicesDetails")}
                  </Typography>

                  <div
                    className="cursor-pointer"
                    onClick={handleCloseDeviceBinModal}
                  >
                    <Icons name="close" width={23} height={23} />
                  </div>
                </div>
              }
            >
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="nativeId" hover>
                      <TableCell variant="head">
                        {i18n.t("trashBinDevices.TrashBinDeviceImei")}
                      </TableCell>
                      <TableCell>
                        {marker?.device?.nativeId
                          ? marker?.device?.nativeId
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow key="deviceModel" hover>
                      <TableCell variant="head">
                        {i18n.t("trashBinDevices.TrashBinDeviceModel")}
                      </TableCell>
                      <TableCell>
                        {marker?.device?.model ? marker?.device?.model : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow key="manufacturer" hover>
                      <TableCell variant="head">
                        {i18n.t("trashBinDevices.TrashBinDeviceManufacturer")}
                      </TableCell>
                      <TableCell>
                        {marker?.device?.manufacturer
                          ? marker?.device?.manufacturer
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow key="type" hover>
                      <TableCell variant="head">
                        {i18n.t("trashBinDevices.TrashBinDevicesTypes")}
                      </TableCell>
                      <TableCell>
                        {marker?.device?.type === 0 ? "Infrared" : "Ultrasonic"}
                      </TableCell>
                    </TableRow>

                    <TableRow key="status" hover>
                      <TableCell variant="head">
                        {i18n.t("trashBinDevices.ConfirmStatusDeviceTitle")}
                      </TableCell>
                      <TableCell>
                        {marker?.device?.status
                          ? `${i18n.t("trashBinDevices.Active")}`
                          : `${i18n.t("trashBinDevices.Inactive")}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </MainCard>
          </Modal>
        </>
      </>
    </>
  );
};

export default TrashbinInfoWindow;
