import MainCard from "../Theme/Cards/MainCard";
import { Grid, Typography, useTheme } from "@mui/material";
import { themeColors } from "../../Config/Themes/theme";
import { useTranslation } from "react-i18next";
import Icons from "../../assets/icons";

const NoDataCard = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const { t } = useTranslation();
  return (
    <MainCard className="p-[28vh] border-[1px] border-[#D1D5DB]">
      <div className="flex items-center justify-center">
        <Icons name="noDataIcon" width={71} height={66} />
      </div>
      <Typography
        sx={{ color: colors.greyAccent.dark2 }}
        className="text-center mt-4 justify-center items-center text-2xl "
      >
        {t("noDataCard.NoData")}
      </Typography>
    </MainCard>
  );
};

export default NoDataCard;
