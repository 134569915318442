import React, { Component } from "react";
import {
  highBinFullnessKey,
  lowBinFullnessKey,
  mediumBinFullnessKey,
  superLowBinFullnessKey,
} from "../../../Config/constant";

export const calculateTrashBinThresholdRange = (thresholds, thresholdValue) => {
  if (thresholdValue >= 0 && thresholdValue <= thresholds.superLowBinFullness) {
    return superLowBinFullnessKey;
  } else if (
    thresholdValue > thresholds.superLowBinFullness &&
    thresholdValue <= thresholds.lowBinFullness
  ) {
    return lowBinFullnessKey;
  } else if (
    thresholdValue > thresholds.lowBinFullness &&
    thresholdValue <= thresholds.mediumBinFullness
  ) {
    return mediumBinFullnessKey;
  } else if (
    thresholdValue > thresholds.mediumBinFullness &&
    thresholdValue <= thresholds.highBinFullness
  ) {
    return highBinFullnessKey;
  } else {
    return null;
  }
};
